import PropTypes from 'prop-types';
import React, { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PatchesContext } from '../../../../context';
import { isSystemInitialisedSelector } from '../../../../state/ui/selectors';
import { mapOptions } from '../../../../utils/googleMapsHelpers';
import styles from './PatchesMap.module.scss';

const PatchesMap = ({ patches }) => {
  const {
    newPatchActive,
    selectedPatchId,
  } = useContext(PatchesContext);

  const mapRef = createRef();
  const [map, setMap] = useState(null);
  const [features, setFeatures] = useState([]);
  const isSystemInitialised = useSelector(isSystemInitialisedSelector);

  const initMap = useCallback(() => {
    if (!map && mapRef.current) {
      const googleMap = new window.google.maps.Map(mapRef.current, mapOptions({}));
      setMap(googleMap);
    }
  }, [map, mapRef]);

  useEffect(() => {
    if (map) {
      features.forEach(feature => map.data.remove(feature));
      setFeatures([]);

      // TODO: improve colors assigned to patches
      const fillColor = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];

      patches.forEach((patch, index) => {
        const parsedGeoJSON = {
          type: 'Feature',
          properties: {
            id: patch.id,
            name: patch.name,
            color: fillColor[(index + fillColor.length) % fillColor.length],
          },
          name: patch.name,
          geometry: JSON.parse(patch.gis_coordinates),
        };
        const feature = map.data.addGeoJson(parsedGeoJSON, 'name');
        setFeatures(oldValues => [...oldValues, ...feature]);
      });

      map.data.setStyle(feature => ({
        fillColor: feature.getProperty('color'),
        strokeOpacity: 0.5,
        strokeWeight: 1,
      }));
    }
  }, [map, patches, newPatchActive, selectedPatchId]);

  useEffect(() => {
    if (map) {
      map.data.setStyle(feature => ({
        fillColor: feature.getProperty('color'),
        fillOpacity: feature.getProperty('id') === selectedPatchId ? 0.5 : 0.3,
        strokeOpacity: feature.getProperty('id') === selectedPatchId ? 1 : 0.5,
        strokeWeight: feature.getProperty('id') === selectedPatchId ? 3 : 1,
      }));
    }
  }, [map, patches, selectedPatchId]);

  useEffect(() => {
    if (isSystemInitialised) {
      initMap();
    }
  }, [isSystemInitialised, initMap]);

  return (
    <div className={styles.mapContainer}>
      <div ref={mapRef} className={styles.map} />
    </div>
  );
};

PatchesMap.defaultProps = {
  patches: [],
};

PatchesMap.propTypes = {
  patches: PropTypes.arrayOf(PropTypes.object),
};

export default PatchesMap;
