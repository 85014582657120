import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { UserRole } from '../../../../constants';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import { parse } from '../../../../utils/queryString';
import {
  SLTable,
  SLTableCellDateFormatter,
  SLTableCellDateFormatterType,
  SLTableCellEnumFormatter,
  SLTableCellFormatter,
  SLTableCellMobileFormatter,
} from '../../../common';
import { RoleChecker } from '../../../layout';
import {
  AuditorNameCellFormatter,
} from '../../Audits/AuditsTable/CellFormatters';
import AuditorsTableBulkOperations from '../AuditorsTableBulkOperations/AuditorsTableBulkOperations.component';

const DATA_CY = 'auditors-table';

const columns = transportAvailableType => [
  {
    accessor: 'name',
    label: 'Name',
    Cell: AuditorNameCellFormatter,
  },
  {
    accessor: 'home_post_code',
    label: 'Home post code',
  },
  {
    accessor: 'term_post_code',
    label: 'Term post code',
  },
  {
    accessor: 'tertiary_post_code',
    label: 'Alternative post code',
  },

  {
    accessor: 'date_of_birth',
    label: 'Age',
    Cell: SLTableCellDateFormatter({
      type: SLTableCellDateFormatterType.RELATIVE,
    }),
  },
  {
    accessor: 'mobile_number',
    label: 'Mobile number',
    Cell: SLTableCellMobileFormatter(),
  },
  {
    accessor: 'transport_available',
    label: 'Transport Available',
    Cell: SLTableCellEnumFormatter({ enumObject: transportAvailableType }),
  },

];

const unapprovedColumns = transportAvailableType => [
  {
    accessor: 'name',
    label: 'Name',
    Cell: SLTableCellFormatter({
      linkFormat: '/auditors/#id/view',
      textFormat: '#name',
    }),
  },
  {
    accessor: 'home_post_code',
    label: 'Home post code',
  },
  {
    accessor: 'term_post_code',
    label: 'Term post code',
  },
  {
    accessor: 'tertiary_post_code',
    label: 'Alternative post code',
  },
  {
    accessor: 'date_of_birth',
    label: 'Age',
    Cell: SLTableCellDateFormatter({
      type: SLTableCellDateFormatterType.RELATIVE,
    }),
  },
  {
    accessor: 'mobile_number',
    label: 'Mobile number',
  },
  {
    accessor: 'application_date',
    label: 'Application date',
    Cell: SLTableCellDateFormatter({ type: SLTableCellDateFormatterType.CUSTOM }),
  },
  {
    accessor: 'transport_available',
    label: 'Transport Available',
    Cell: SLTableCellEnumFormatter({ enumObject: transportAvailableType }),
  },
];

const TransportAvailableTypeSelector = state => enumOptionsSelector(state, 'transport_available_type');

const AuditorsTable = ({
  data,
  pages,
  onLimitChange,
  onPageChange,
  onSortByChange,
  currentLimit,
  currentPage,
  currentSortBy,
  refetch,
}) => {
  const { search } = useLocation();
  const { auditor_status } = parse(search);

  const role = useUserRole();
  const transportAvailableType = useSelector(TransportAvailableTypeSelector);

  const [selectedAuditors, setSelectedAuditors] = useState([]);

  const onRowSelectionChange = rows => {
    if (!_isEqual(rows.map(row => row.id), selectedAuditors)) {
      setSelectedAuditors(rows.map(row => row.id));
    }
  };

  const bulkAllowedRoles = [UserRole.AREA_MANAGER, UserRole.CLIENT_SERVICES, UserRole.DATA];

  const selectedColumns = () => {
    if (auditor_status === 'unapproved') {
      return unapprovedColumns(transportAvailableType);
    }

    return columns(transportAvailableType);
  };

  return (
    <>
      <RoleChecker
        allowedRoles={bulkAllowedRoles}
        refetch={refetch}
      >
        <AuditorsTableBulkOperations
          refetch={refetch}
          selectedAuditors={selectedAuditors}
        />
      </RoleChecker>
      <SLTable
        columns={selectedColumns()}
        currentLimit={currentLimit}
        currentPage={currentPage}
        currentSortBy={currentSortBy}
        data={data}
        dataCy={DATA_CY}
        pages={pages}
        rowSelector={bulkAllowedRoles.includes(role)}
        onLimitChange={onLimitChange}
        onPageChange={onPageChange}
        onRowSelectionChange={onRowSelectionChange}
        onSortByChange={onSortByChange}
      />
    </>
  );
};

AuditorsTable.defaultProps = {
  currentSortBy: {},
};

AuditorsTable.propTypes = {
  currentLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  currentSortBy: PropTypes.object,
};

export default AuditorsTable;
