import React, { useMemo } from 'react';

import { LAYER_ID } from '../helpers';
import styles from './MapTooltip.module.scss';
import AuditContent from './tooltips/AuditContent.component';
import AuditorContent from './tooltips/AuditorContent.component';

const MapTooltip = ({ data }) => {
  const { layerId, object, x, y } = data;

  const type = useMemo(() => {
    const layerType = {
      [LAYER_ID.AUDITS]: {
        type: 'audit',
        icon: 'flag icon',
        title: object.internal_id,
        content: <AuditContent content={object} />,
      },
      [LAYER_ID.AUDITORS]: {
        type: 'auditor',
        icon: 'user icon',
        title: object.name,
        content: <AuditorContent content={data} />,
      },
    };
    return layerType[layerId] || layerType.default;
  }, [layerId, data]);

  return (
    <div
      className={styles.tooltipContainer}
      style={{
        left: x,
        top: y,
      }}
    >
      <h4>
        <span style={{ marginRight: '5px' }}>
          <i className={type.icon} />
        </span>
        <span value="">
          {type.title}
        </span>
      </h4>
      {type.content}
    </div>
  );
};

export default MapTooltip;
