import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';

import { auditorsAPI, legalAPI } from '../../../../../api';
import getFieldAuditorStatus from '../../../../../fieldLogic/auditor';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import checkGender from '../../../../../utils/filterOutGender';
import { formatPhoneNumber } from '../../../../../utils/mobileHelpers';
import {
  FormFieldCheckboxWrapper,
  FormFieldDateTimeWrapper,
  FormFieldImageUploadWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
} from '../../../../common';
import { countryPrefixes } from '../../../RegisterAuditor/RegisterAuditorFormWrapper/helpers';
import { formatStatusSelector } from '../../helpers';

const auditorStatusOptionsSelector = state => enumOptionsSelector(state, 'auditor_status_type');
const genderOptionsSelector = state => enumOptionsSelector(state, 'gender_type');
const accessToRelationshipOptionsSelector = state => enumOptionsSelector(state, 'relationship_type');
const pronounsOptionsSelector = state => enumOptionsSelector(state, 'pronouns_type');

const EditAuditorProfileTab = ({ role, mode }) => {
  const { values, setFieldValue } = useFormikContext();

  const auditorStatusOptions = useSelector(auditorStatusOptionsSelector);
  const newAuditorStatusTypeOptions = formatStatusSelector(auditorStatusOptions);

  const genderOptions = useSelector(genderOptionsSelector).filter(item => checkGender(item.value));
  const relationshipOptions = useSelector(accessToRelationshipOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));
  const pronounsOptions = useSelector(pronounsOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));

  const { mutate: updateNotifications } = useMutation(auditorsAPI.enableNotifications);
  const { mutate: updateFacialImagesConsent } = useMutation(legalAPI.updateFacialImagesConsent);
  const { mutate: updateSpecialCategoryConsent } = useMutation(legalAPI.updateSpecialCategoryConsent);
  const { mutate: updateCommunicationsConsent } = useMutation(legalAPI.updateCommunicationsConsent);

  const handleNotificationToggle = async checked => {
    setFieldValue('enable_notifications', checked);
    updateNotifications(checked);
  };

  const handleFacialImagesToggle = async checked => {
    setFieldValue('facial_images_consent', checked);
    updateFacialImagesConsent(checked);
  };

  const handleSpecialCategoryToggle = async checked => {
    setFieldValue('special_category_consent', checked);
    updateSpecialCategoryConsent(checked);
  };

  const handleCommunicationsToggle = async checked => {
    setFieldValue('communications_consent', checked);
    updateCommunicationsConsent(checked);
  };

  const handlePhoneChange = value => {
    const rawValue = value;

    setFieldValue('mobile_number_raw', rawValue);

    const formattedNumber = formatPhoneNumber(values.mobile_prefix, rawValue);
    setFieldValue('mobile_number', formattedNumber);
  };

  return (
    <>
      <FormFieldWrapper
        inline
        label="First name"
        name="first_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Middle Name"
        name="middle_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Last Name"
        name="last_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="Preferred first name"
        name="preferred_first_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="Preferred last name"
        name="preferred_last_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Preferred pronouns"
        name="preferred_pronouns"
        options={pronounsOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {values.preferred_pronouns === 'Other' && (
        <FormFieldWrapper
          inline
          required
          label="Other pronoun"
          name="other_pronoun"
          placeholder="Other pronoun"
          onStateHandler={getFieldAuditorStatus(role, mode)}

        />)}

      <Divider />

      <FormFieldDateTimeWrapper
        inline
        label="Date of Birth"
        name="date_of_birth"
        time={false}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldSelectWrapper
        inline
        label="Gender"
        name="gender"
        options={genderOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="E-mail Address"
        name="email"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldSelectWrapper
        inline
        label="Country code"
        name="mobile_prefix"
        options={countryPrefixes.map(country => ({
          value: `${country.code}-${country.dialCode}`,
          text: `${country.flag} ${country.name} (${country.dialCode})`,
        }))}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldWrapper
        inline
        label="Mobile number"
        name="mobile_number"
        onChange={handlePhoneChange}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldWrapper
        inline
        label="Emergency Contact Name"
        name="emergency_name"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Emergency Relationship"
        name="emergency_relationship"
        options={relationshipOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        label="Emergency Contact Number"
        name="emergency_phone"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldSelectWrapper
        inline
        label="Status"
        name="auditor_status"
        options={newAuditorStatusTypeOptions}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <Divider />

      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Recent Photograph (please take a selfie)"
        name="image"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Scanned Copy of Passport"
        name="passport"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />
      <FormFieldImageUploadWrapper
        inline
        help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
        label="Scanned Copy of ID"
        name="identification"
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      {(['GB', 'IE'].includes(values?.nationality) ? null : (
        <FormFieldImageUploadWrapper
          inline
          help="Files must be less than 100 MB. Allowed file types: .jpg, .png, .gif"
          label="Scanned Copy of Visa"
          name="visa"
          onStateHandler={getFieldAuditorStatus(role, mode)}
        />
      ))}

      <Divider />

      <FormFieldCheckboxWrapper
        inline
        toggle
        label="Enable Notifications"
        name="enable_notifications"
        onChange={(_, { checked }) => handleNotificationToggle(checked)}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldWrapper
        inline
        readOnly
        label="GDPR"
        name="gdpr"
      />

      <FormFieldCheckboxWrapper
        inline
        toggle
        help="I consent to Serve Legal using my facial images (and meta data) and other personal data and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
        label=""
        name="facial_images_consent"
        onChange={(_, { checked }) => handleFacialImagesToggle(checked)}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldCheckboxWrapper
        inline
        toggle
        help="I consent to Serve Legal using my special category data such as my ethnicity and occupational health information and sharing such data with their clients for the purpose of facial recognition verification and in relation to the services Serve Legal provides."
        label=""
        name="special_category_consent"
        onChange={(_, { checked }) => handleSpecialCategoryToggle(checked)}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

      <FormFieldCheckboxWrapper
        inline
        toggle
        help="Please check this box to confirm that you consent to receiving communications from us regarding your Serve Legal employment, job opportunities, and marketing initiatives."
        label=""
        name="communications_consent"
        onChange={(_, { checked }) => handleCommunicationsToggle(checked)}
        onStateHandler={getFieldAuditorStatus(role, mode)}
      />

    </>
  );
};

EditAuditorProfileTab.propTypes = {
  mode: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
};

export default EditAuditorProfileTab;
