import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Redirect, Route as ReactRouterDomRoute, Router, Switch } from 'react-router-dom';

import { Fallback } from '../components/common';
import Account from '../components/pages/Account/Account.component';
import Map from '../components/pages/Map/MapMobile/MapMobile.component';
import PowerBIReports from '../components/pages/PowerBIReports/PowerBIReports.component';
import TipsAndDocs from '../components/pages/TipsAndDocs/TipsAndDocs.component';
import { Entity, UserRole } from '../constants';
import history from '../history';
import isMobile from '../utils/isMobile';
import EntityRouter from './EntityRouter';
import AppRoute from './routes';

// Enable Sentry tracing on routes
const Route = Sentry.withSentryRouting(ReactRouterDomRoute);

// const PowerBIReports = lazy(() => import('../components/pages/PowerBIReports/PowerBIReports.component'));
// const TipsAndDocs = lazy(() => import('../components/pages/TipsAndDocs/TipsAndDocs.component'));
// const Map = lazy(() => import('../components/pages/Map/MapMobile/MapMobile.component'));

const allowedEntities = role => {
  const entities = {
    auditor: [Entity.AUDITS],
    area_manager: [Entity.AUDITORS, Entity.AUDITS],
    client: [Entity.AUDITS, Entity.ORDERS, Entity.ARCHIVES],
    client_services: [Entity.AUDITORS, Entity.AUDITS, Entity.ORDERS, Entity.SITES, Entity.TAXONOMY, Entity.USERS],
    // eslint-disable-next-line max-len
    data: [Entity.AUDITORS, Entity.AUDITS, Entity.CLIENTS, Entity.LOGICS, Entity.ORDERS, Entity.SITES, Entity.SURVEYS, Entity.PATCHES, Entity.TAXONOMY, Entity.USERS],
  };

  return entities[role] || [];
};

const DashboardRouter = ({ role }) => (
  <Router history={history}>
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route
          component={Account}
          exact={false}
          path={AppRoute.ACCOUNT.path}
        />
        {[UserRole.CLIENT].includes(role)
          ? (
            <Route
              exact
              component={PowerBIReports}
              path={AppRoute.REPORTS.path}
            />
          )
          : null}
        {[UserRole.AREA_MANAGER, UserRole.AUDITOR].includes(role)
          ? (
            <Route
              exact
              component={TipsAndDocs}
              path={AppRoute.TIPS_AND_DOCS.path}
            />
          ) : null}
        {[UserRole.AUDITOR].includes(role) && isMobile ? (
          <Route
            exact
            component={Map}
            path={AppRoute.MAP.path}
          />) : null}
        <Route
          path={allowedEntities(role).map(entity => `/${entity}`)}
        >
          <EntityRouter role={role} />
        </Route>
        <Redirect to={`/${allowedEntities(role)[0]}`} />
      </Switch>
    </Suspense>
  </Router>
);

DashboardRouter.propTypes = {
  role: PropTypes.string.isRequired,
};

export default DashboardRouter;
