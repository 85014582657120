import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { generateWhatsAppLink } from '../../../../../utils/mobileHelpers';
import styles from '../MapTooltip.module.scss';

const getIconName = status => (status ? 'check' : 'close');

const AuditorContent = ({ content }) => {
  const { briefed_for_narv, briefed_for_ssl, right_to_work, auditor_pass_rate, object: { home_post_code, term_post_code, age, auditor_status, transport_available, mobile_number } } = content;
  const transportAvailableOptionSelector = state => enumOptionsSelector(state, 'transport_available_type');
  const auditorStatusOptionSelector = state => enumOptionsSelector(state, 'auditor_status_type');

  const transportAvailableOption = useSelector(transportAvailableOptionSelector);
  const auditorStatusOptions = useSelector(auditorStatusOptionSelector);
  return (
    <div className={styles.tooltipContent}>
      <p>
        <strong>
Home postcode:
        </strong>
        {home_post_code}
      </p>
      <p>
        <strong>
Term postcode:
        </strong>
        {term_post_code}
      </p>
      <p>
        <strong>
Age:
        </strong>
        {age}
      </p>
      <p>
        <strong>
SSL:
        </strong>
      XXXX
      </p>
      <p>
        <strong>
NARV:
        </strong>
      XXXX
      </p>
      <p>
        <strong>
Access to car:
        </strong>
        {transportAvailableOption[transport_available]}
      </p>
      <p>
        <strong>
Pass Rate:
        </strong>
        <span className={auditor_pass_rate >= 5 ? styles.success : styles.error}>
          {auditor_pass_rate !== null && `${auditor_pass_rate}%`}
        </span>
      </p>
      <p>
        <strong>
Available:
        </strong>
        <span className={auditor_status === 'active' ? styles.success : styles.error}>
          {auditorStatusOptions.filter(option => option.value === auditor_status)[0]?.text}
        </span>
      </p>
      <section className={styles.auditorExtraInfo}>
        <ul className={styles.checks}>
          <li className={right_to_work ? styles.success : styles.error}>
            <Icon name={getIconName(right_to_work)} />
RTW
          </li>
          <li className={briefed_for_ssl ? styles.success : styles.error}>
            <Icon name={getIconName(briefed_for_ssl)} />
SSL
          </li>
          <li className={briefed_for_narv ? styles.success : styles.error}>
            <Icon name={getIconName(briefed_for_narv)} />
NARV
          </li>
        </ul>
        <div className={styles.ctaContainer}>
          <Button className={styles.whatsAppBtn} type="button">
            <a href={generateWhatsAppLink('+44', mobile_number)}>
              <Icon name={'whatsapp'} style={{ fontSize: 20 }}/>
              {mobile_number}
            </a>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default AuditorContent;

AuditorContent.propTypes = {
  content: PropTypes.shape({
    age: PropTypes.string,
    auditor_status: PropTypes.string,
    home_post_code: PropTypes.string,
    mobile_number: PropTypes.string,
    term_post_code: PropTypes.string,
    transport_available: PropTypes.string,
  }).isRequired,
};
