import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Grid, GridColumn, GridRow, Image } from 'semantic-ui-react';

import { FullScreenImageModalV2 } from '../../../../common';
import styles from './AuditorDetailsImages.module.scss';

const DATA_CY = 'auditor-detail-images';

const images = [
  { label: 'Scanned Copy of Passport', src: 'passport' },
  { label: 'Scanned Copy of ID', src: 'identification' },
];

const AuditorDetailsImages = ({ data, refetch, title }) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const [modalName, setModalName] = useState(null);

  return (
    <Form>
      <Grid columns={1} data-cy={DATA_CY}>
        {title && <h3 className={styles.title}>
          {title}
        </h3>}
        <GridRow>
          <GridColumn width={16}>

            <Form.Field>
              <label className={styles.label}>
                {'Images'}
              </label>
              <GridRow className={styles.row}>
                {images.filter(image => !!data[image.src])
                  .map(image => (
                    <GridColumn key={image.src} className={styles.columnWrapper} width={8}>
                      <div key={image.src}>
                        <div className={styles.container}>
                          <div className={styles.imageContainer}>
                            <Image
                              rounded
                              className={styles.image}
                              inline={true}
                              size="medium"
                              src={data[image.src]}
                              onClick={() => {
                                setModalImgSrc(data[image.src]);
                                setModalName(image.src);
                              }
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </GridColumn>
                  ))}
              </GridRow>
            </Form.Field>

            {data.visa && (
              <Form.Field>
                <label className={styles.label}>
                  {'Right To Work PDF'}
                </label>

                <GridRow className={styles.row}>
                  <GridColumn key={data.visa} className={styles.columnWrapper} width={8}>
                    <div key={data.visa}>
                      <div className={styles.container}>
                        <div className={styles.imageContainer}>
                          <Image
                            rounded
                            className={styles.image}
                            inline={true}
                            size="medium"
                            src={data.visa}
                            onClick={() => {
                              setModalImgSrc(data.visa);
                              setModalName('visa');
                            }
                            }
                          />
                        </div>

                      </div>
                    </div>
                  </GridColumn>
                </GridRow>
              </Form.Field>
            )}
            {
              modalImgSrc && (
                <FullScreenImageModalV2
                  data={data}
                  entityId={data.id}
                  imgSrc={modalImgSrc}
                  name={modalName}
                  refetch={refetch}
                  onClose={() => setModalImgSrc(null)}
                />
              )
            }
          </GridColumn>
        </GridRow>
      </Grid>

    </Form>
  );
};

AuditorDetailsImages.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default AuditorDetailsImages;
