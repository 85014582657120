import appConfig from './appConfig';
import { alertsConfig, notificationAlertsConfig } from './configureAlerts';
import configureAmplify from './configureAmplify';
import configureGoogleMaps from './configureGoogleMaps';
import configureMoment from './configureMoment';
import queryConfig from './configureQueries';
import { setSentryUser } from './configureSentry';

const configureSystem = async () => {
  configureMoment();
  configureAmplify();
  await configureGoogleMaps();
};

export default configureSystem;

export {
  alertsConfig,
  appConfig,
  notificationAlertsConfig,
  queryConfig,
  setSentryUser,
};
