import PropTypes from 'prop-types';
import React from 'react';
import { Grid, GridColumn, GridRow, Icon, Tab } from 'semantic-ui-react';

import { EntityState } from '../../../../constants';
import MapAuxiliary from '../../Map/MapAuxiliary/MapAuxiliary.component';
import { bankInformation, filteredAuditorInformation, legalAnswers } from '../helpers';
import AuditorDetailsImages from './AuditorDetailsImages/AuditorDetailsImages.component';
import AuditorDetailsProfile from './AuditorDetailsProfile/AuditorDetailsProfile.component';
import AuditorDetailsTab from './AuditorDetailsTab/AuditorDetailsTab.component';
import styles from './AuditorDetailsView.module.scss';

const DATA_CY = 'auditor-details-view';

const AddressSection = ({ data, addressType }) => {
  const prefix = addressType;
  const address1 = data[`${prefix}_address_1`];
  const address2 = data[`${prefix}_address_2`];
  const city = data[`${prefix}_city`];
  const postCode = data[`${prefix}_post_code`];
  const county = data[`${prefix}_county`];
  const patchName = data[`${prefix}_patch_name`];
  const coordinates = data[`${prefix}_coordinates`];
  const currentCoordinates = data.current_coordinates;

  const isCurrent = coordinates && currentCoordinates
    && coordinates.lat === currentCoordinates.lat
    && coordinates.lng === currentCoordinates.lng;

  if (!address1 && !address2 && !city && !postCode && !county) {
    return null;
  }

  const titles = {
    home: 'Home Address',
    term: 'Term Address',
    tertiary: 'Alternative Address',
  };

  return (
    <div className={isCurrent ? styles.currentAddressBox : styles.addressBox} data-cy={`${DATA_CY}-${prefix}-address`}>
      <div className={styles[`${addressType}Title`]}>
        <Icon name="map marker alternate"/>
        {titles[addressType]}
      </div>
      <div className={styles.addressContent}>
        {address1 && <div className={isCurrent ? styles.labelCurrent : styles.label}>
          {address1}
        </div>}
        {address2 && <div className={isCurrent ? styles.labelCurrent : styles.label}>
          {address2}
        </div>}
        {city && <div className={isCurrent ? styles.labelCurrent : styles.label}>
          {city}
        </div>}
        {postCode && <div className={isCurrent ? styles.labelCurrent : styles.label}>
          {postCode}
        </div>}
        {county && <div className={isCurrent ? styles.labelCurrent : styles.label}>
          {county}
        </div>}
      </div>
      <div className={styles.coordinates}>
        {patchName && <span className={styles.patchName}>
          <Icon name="flag outline"/>
          {patchName}
        </span>}
        {isCurrent && <span className={styles.currentAddress}>
          <Icon name="check circle outline"/>
          Current Address
        </span>}
      </div>
    </div>
  );
};

AddressSection.propTypes = {
  addressType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    current_coordinates: PropTypes.object,
  }).isRequired,
};

const panes = (data, refetch) => [
  {
    menuItem: 'Auditor information',
    render: () => (
      <Tab.Pane className="basic" data-cy={`${DATA_CY}-tab-auditor-information`}>
        <Grid doubling={true} stackable={true}>
          <GridRow>
            <GridColumn width={8}>
              <div className={styles.auditorInformation}>
                <AuditorDetailsTab
                  data={data}
                  dataCy="auditor-information"
                  display={filteredAuditorInformation(data)}
                  title="Personal information"
                />
              </div>
              <div className={styles.addressContainer}>
                {['home', 'term', 'tertiary'].map(addressType => (
                  <AddressSection key={addressType} addressType={addressType} data={data} />
                ))}
              </div>
              <MapAuxiliary data={data} showFooter={false} showHeader={false} type={EntityState.DETAIL}/>
            </GridColumn>
            <GridColumn className={styles.legalAnswers} width={4}>
              <AuditorDetailsTab
                data={data}
                dataCy="auditor-information"
                display={legalAnswers}
                title="Serve Legal answers"
              />
            </GridColumn>
            <GridColumn width={4}>
              <AuditorDetailsImages
                data={data}
                refetch={refetch}
                title="Uploads"
                type={EntityState.DETAIL}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Bank Information',
    render: () => (
      <Tab.Pane className="basic" data-cy={`${DATA_CY}-tab-bank-information`}>
        <AuditorDetailsTab
          data={data}
          dataCy="bank-information"
          display={bankInformation}
        />
      </Tab.Pane>
    ),
  },
];

const AuditorDetailsView = ({ data, refetch }) => (
  <div data-cy={DATA_CY}>
    <AuditorDetailsProfile data={data} dataCy={DATA_CY} refetch={refetch}/>
    <div className={styles.additionalInfo}>
      <Tab
        data-cy={`${DATA_CY}-tabs`}
        menu={{ secondary: true, pointing: true, className: styles.customTabMenu }}
        panes={panes(data, refetch)}
        refetch={refetch}
      />
    </div>
  </div>
);

AuditorDetailsView.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AuditorDetailsView;
