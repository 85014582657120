import moment from 'moment';
import * as Yup from 'yup';

import { Country, DateTimeFormat, UserRole } from '../../../constants';
import { shareCodeValidationWithoutSpaces, trimValidation, validateMobileNumber } from '../../../utils/validationHelpers';

export const getFormattedDate = date => moment(date).format('DD/MM/YY');

export const auditorFilterValidationSchema = Yup.object().shape({
  home_post_code: Yup.string(),
  internal_id: Yup.string(),
  name: Yup.string(),
  number_of_audits: Yup.number().min(0),
  patch: Yup.string(),
  status: Yup.string(),
  term_post_code: Yup.string(),
  tertiary_post_code: Yup.string(),
});

export const isNumericString = value => /^\d+$/.test(value);

export const auditorFilterDefaultValues = {
  home_post_code: '',
  internal_id: '',
  name: '',
  number_of_audits: 0,
  patch: '',
  status: '',
  term_post_code: '',
  tertiary_post_code: '',
};

export const hasVisaExpirationDate = [
  { key: 'yes', value: true, text: 'Yes' },
  { key: 'no', value: false, text: 'No' },
];

const statusLabelDictionary = {
  on_hold: 'on hold - action required',
  on_hold_no_work: 'on hold - no work',
};

const onHoldNamingDictionary = {
  on_hold: 'On Hold - Action Required',
  on_hold_no_work: 'On Hold - No Work',
};

const statusButtonsDictionary = {
  'On Hold': 'On Hold - Action Required',
  'On Hold No Work': 'On Hold - No Work',
};

export const formatStatusLabel = status => statusLabelDictionary[status] || status;

export const formatStatusSelector = auditorStatusOptions => auditorStatusOptions.map(option => ({
  ...option,
  text: onHoldNamingDictionary[option.value] || option.text,
}));

export const formatStatus = status => statusButtonsDictionary[status] || status;

const dateFormatter = (value, data) => (
  data[value]
    ? moment(data[value]).format(DateTimeFormat.DAY_MONTH_YEAR)
    : ''
);

export const auditorInformation = [
  [
    {
      label: 'Date of birth',
      value: 'date_of_birth',
      formatter: dateFormatter,
    },
    {
      label: 'Nationality',
      value: 'nationality',
    },
  ],
  [
    {
      label: 'Visa Expiration',
      value: 'visa_expiration',
      formatter: (value, data) => (['GB', 'IE'].includes(data?.nationality) ? 'N/A' : moment(data[value]).format(DateTimeFormat.DAY_MONTH_YEAR)),
    },
    {
      label: 'Share Code',
      value: 'share_code',
      formatter: (value, data) => (data[value] ? data[value] : 'N/A'),
    },
  ],
  [
    {
      label: 'Title',
      value: 'honorific',
    },
    {
      label: 'Marital status',
      value: 'marital_status',
    },
  ],
  [
    {
      label: 'Occupation',
      value: 'occupation',
    },
    {
      label: 'Emergency Contact',
      value: 'emergency_name',
      formatter: (value, data) => `${data?.emergency_name || 'N/A'}, ${data?.emergency_relationship || 'N/A'}, ${data?.emergency_number || 'N/A'}`,
    },
  ],
];

export const filteredAuditorInformation = data => {
  const isExcludedNationality = ['GB', 'IE'].includes(data?.nationality);
  return auditorInformation
    .map(row => row.filter(field => {
      if (isExcludedNationality && ['visa_expiration', 'share_code'].includes(field.value)) {
        return false;
      }
      return true;
    }));
};

export const legalAnswers = [[
  {
    label: 'If recommended, who recommended you?',
    value: 'recommended_by',
    default: 'N/A',
  },
  {
    label: 'Please give details',
    value: 'hear_about_us_details',
    default: 'N/A',
  },
  {
    label: 'Why do you want to work with ServeLegal?',
    value: 'why_servelegal',
    default: 'N/A',
  },
  {
    label: 'Tell us a bit about yourself',
    value: 'about_yourself',
    default: 'N/A',
  },
  {
    label: 'Can you travel outside your local area?',
    value: 'travel_outside',
    default: 'N/A',
  },
]];

export const bankInformation = [
  [
    {
      label: 'Name on Card / Bank Account Holder',
      value: 'card_name',
      defaultValue: 'N/A',
    },
    {
      label: 'Bank account number',
      value: 'account_number',
      defaultValue: 'N/A',
    },
    {
      label: 'SWIFT code',
      value: 'swift_code',
      defaultValue: 'N/A',
    },
    {
      label: 'What circumstances of work you have?',
      value: 'circumstances',
      defaultValue: 'N/A',
    },
  ],
  [
    {
      label: 'Bank Country',
      value: 'bank_country',
      defaultValue: 'N/A',
    },
    {
      label: 'Bank Sort Code',
      value: 'sort_code',
      defaultValue: 'N/A',
    },
    {
      label: 'IBAN Number',
      value: 'iban_number',
      defaultValue: 'N/A',
    },
    {
      label: 'What kind of student loan do you have?',
      value: 'student_loans',
      defaultValue: 'N/A',
    },
  ],
  [],
];

export const serveLegalAnswersInformation = [
  [
    {
      label: 'If recommended, who recommended you?',
      value: 'recommended_by',
      default: 'N/A',
    },
    {
      label: 'Please give details',
      value: 'hear_about_us_details',
      default: 'N/A',
    },
    {
      label: 'Why do you want to work with ServeLegal?',
      value: 'why_servelegal',
      default: 'N/A',
    },
    {
      label: 'Tell us a bit about yourself',
      value: 'about_yourself',
      default: 'N/A',
    },
    {
      label: 'Can you travel outside your local area?',
      value: 'travel_outside',
      default: 'N/A',
    },
  ],
];

export const auditorEditValidationSchema = role => Yup.object().shape({
  about_yourself: Yup.string().nullable(),
  account_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}`).matches(/^[0-9]{8}?$/, {
        message: 'Account numbers must be only numbers and 8 characters long',
      })
      : Yup.mixed().notRequired())) // For Ireland
    : Yup.string().matches(/^[0-9]{8}?$/, {
      message: 'Account numbers must be 8 characters long',
    }),
  auto_geolocation_interval: Yup.number().nullable(),
  bank_country: role === UserRole.AUDITOR
    ? Yup.string().required('Country required')
    : Yup.string().nullable(),
  card_name: role === UserRole.AUDITOR
    ? Yup.string().required('Field required')
    : Yup.string().nullable(),
  circumstances: role === UserRole.AUDITOR
    ? Yup.string().required().typeError('Field required')
    : Yup.string().nullable(),
  current_coordinates: Yup.object().nullable(),
  date_of_birth: Yup.string(),
  email: Yup.string(),
  emergency_name: Yup.string().required('Please enter the name of your emergency contact').nullable(),
  emergency_phone: Yup.string().required('Please enter the number of your emergency contact').nullable(),
  emergency_relationship: Yup.string().required('Please select your relationship with your emergency contact').nullable(),
  evidence_not_required: Yup.boolean(),
  first_name: Yup.string().test('trim', 'Please remove spaces before/after the first name', trimValidation),
  gender: Yup.string(),
  hear_about_us_details: Yup.string().nullable(),
  home_address_1: role === UserRole.AUDITOR
    ? Yup.string().required('Home address is required')
    : Yup.string().nullable(),
  home_address_2: Yup.string().nullable(),
  home_county: role === UserRole.AUDITOR
    ? Yup.string().required('Home country is required')
    : Yup.string().nullable(),
  home_post_code: role === UserRole.AUDITOR
    ? Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Home post code is required'),
      })
    : Yup.string().nullable(),
  honorific: Yup.string(),
  iban_number: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}: IBAN`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  last_name: Yup.string().test('trim', 'Please remove spaces before/after the last name', trimValidation),
  marital_status: Yup.string(),
  middle_name: Yup.string().test('trim', 'Please remove spaces before/after the middle name', trimValidation).nullable(),
  mobile_number: Yup.string()
    .required('Please enter your mobile number')
    .test('valid-phone', 'Please enter a valid mobile number', validateMobileNumber),
  mobile_prefix: Yup.string().required('Please select your prefix'),
  nationality: Yup.string().required('Please select your nationality'),
  ni_pps: role === UserRole.AUDITOR ? Yup.string()
    .when('country', {
      is: 'Ireland',
      then: Yup.string()
        .matches(/^[0-9]{7}[a-zA-Z]{1}[a-zA-Z]?$/, {
          message: 'Incorrect format - Use a valid PPS Number',
          excludeEmptyString: false,
        })
        .required('Please enter your number'),
      otherwise: Yup.string()
        .matches(/^[a-zA-Z]{2}[0-9]{6}[a-zA-Z]{1}$/, {
          message: 'Incorrect format - Use a valid NI Number',
          excludeEmptyString: false,
        })
        .required('Required'),
    }) : Yup.string().nullable(),
  notes: Yup.string().nullable(),
  occupation: Yup.string().nullable(),
  other_pronoun: Yup.string().when('preferred_pronouns', {
    is: 'Other',
    then: Yup.string().required('Please specify the pronoun you prefer').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  preferred_first_name: Yup.string().required('Please enter a preferred first name').nullable(),
  preferred_last_name: Yup.string().required('Please enter a preferred last name').nullable(),
  preferred_pronouns: Yup.string().required('Please select a preferred pronoun').nullable(),
  recommended_by: Yup.string().nullable(),
  share_code: Yup.string().when('nationality', {
    is: value => ['IE', 'GB'].includes(value),
    then: Yup.string().nullable(),
    otherwise: Yup.string().when('share_code_not_required', {
      is: false,
      then: Yup.string()
        .matches(shareCodeValidationWithoutSpaces, 'Please enter a valid share code')
        .required(
          'Please input your share code to progress. If you do not require a share code to work legally, please select the tickbox above to indicate this.',
        ).nullable(),
      otherwise: Yup.string().nullable(),
    }),
  }),
  share_code_not_required: Yup.boolean(),
  sort_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.GBP
      ? Yup.string().required(`Field required for bank sited in ${bank_country}: Sort Code`).matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
        message: 'The correct format is 00-00-00',
      })
      : Yup.mixed().notRequired()))
    : Yup.string().matches(/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/, {
      message: 'The correct format is 00-00-00',
    }),
  student_loans: role === UserRole.AUDITOR ? Yup.string().required().typeError('Field required') : Yup.string().nullable(),
  swift_code: role === UserRole.AUDITOR
    ? Yup.lazy((val, { parent: { bank_country } }) => (bank_country === Country.IRL
      ? Yup.string().required(`Field required for bank sited in ${bank_country}: Swift Code`)
      : Yup.mixed().notRequired()))
    : Yup.string().nullable(),
  term_address_1: role === UserRole.AUDITOR
    ? Yup.string().required('Term address is required')
    : Yup.string().nullable(),
  term_address_2: Yup.string().nullable(),
  term_county: role === UserRole.AUDITOR
    ? Yup.string().required('Term country is required')
    : Yup.string().nullable(),
  term_post_code: role === UserRole.AUDITOR
    ? Yup.string()
      .when('country', {
        is: 'United Kingdom',
        then: Yup.string().trim().ensure().min(1, 'Term postcode is required'),
      })
    : Yup.string().nullable(),
  transport_available: Yup.string().required('Please select a transport available for you').nullable(),
  travel_outside: Yup.string().nullable(),
  visa_expiration: Yup.date().when('nationality', {
    is: value => ['IE', 'GB'].includes(value),
    then: Yup.date().nullable(),
    otherwise: Yup.date().when(['evidence_not_required', 'visa_expiration_date_required'], {
      is: (evidence_not_required, visa_expiration_date_required) => evidence_not_required === false
        && visa_expiration_date_required === true,
      then: Yup.date()
        .typeError('Please select a valid visa date')
        .min(new Date(), 'Visa cannot be expired.')
        .required('Please enter the expiration date of your visa'),
      otherwise: Yup.date().nullable(),
    }),
  }),
  visa_expiration_date_required: Yup.boolean().when('nationality', {
    is: value => ['IE', 'GB'].includes(value),
    then: Yup.boolean().nullable(),
    otherwise: Yup.boolean().when('evidence_not_required', {
      is: false,
      then: Yup.boolean().required('Please select if your visa has an expiration date').nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
    why_servelegal: Yup.string().nullable(),
  }),
});
