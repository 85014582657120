import React from 'react';
import { Form } from 'semantic-ui-react';

import passportImage from '../../../../../assets/images/documents/Passport.jpg';
import { FormFieldImageUploadWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepSix.module.scss';

const RegisterAuditorFormStepSix = () => (
  <FormLayout subtitle={'1 of 3'} title="Upload images">
    <span className={styles.titleQuestion}>
      {'A photo of your passport'}
    </span>
    <img alt="bsi-logo" className={styles.image} src={passportImage} />
    <p className={styles.noteQuestionItalic}>
      {'To successfully process your application, we need a copy of your passport to assess your right to work in the UK. It will only be reviewed at the time of request, and then only our payroll department will be able to access it to process you as an employee.'}
    </p>

    <p className={styles.noteQuestion}>
      {'Please ensure this is a clear photo opened to your photo page and clearly shows all information, as show in the example above.'}
    </p>

    <Form.Field width={16}>
      <FormFieldImageUploadWrapper
        required
        thumbnail
        accept={['image/jpg', 'image/png']}
        name="passport"
      />
    </Form.Field>
    <span className={styles.noteQuestion}>
      {'Allowed file types: .jpg, .png'}
    </span>
  </FormLayout>
);

export default RegisterAuditorFormStepSix;
