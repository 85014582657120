/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';

import history from '../history';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: ['localhost', /https?:\/\/(?:[a-zA-Z0-9-]+\.)*secure-servelegal\.co\.uk(?:\/.*)?/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const setSentryUser = email => {
  if (email) {
    Sentry.setUser({ email });
  } else {
    Sentry.setUser(null);
  }
};
