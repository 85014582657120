import { handleActions } from 'redux-actions';

import * as actions from './actionCreators';
import initialState from './initialState';

export default {
  googleMapFeature: handleActions(
    {
      [actions.toggleMapView]: state => ({
        ...state,
        enabled: !state.enabled,
      }),
    },
    initialState.googleMapFeature,
  ),
};
