import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const setStartCoordinatesAndDate = ({ auditId, payload }) => axios.post(
  `${appConfig.backendUrl}/api/v1/audits/${auditId}/coordinates`,
  payload,
  { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error sending audit start coordinates'))
  .catch(logError);

export const complete = async ({ auditId, payload, version = 'v5' }) => axios.post(
      `${appConfig.backendUrl}/api/${version}/audits/${auditId}/complete`,
      payload,
      {
        headers: {
          Authorization: getAuthorization(),
          'Content-Type': 'multipart/form-data',
        },
      },
)
  .then(handlingResponse([StatusCodes.OK], 'Error submitting audit'))
  .catch(logError);

export const formRecognizer = ({ auditId, questions, matchings, detections, action }) => axios.post(
    `${appConfig.backendUrl}/api/v5/audits/${auditId}/receipt/recognizer`,
    { questions, matchings, detections, action },
    { headers: { Authorization: getAuthorization() } },
)
  .then(handlingResponse([StatusCodes.OK], 'Error sending audit to code validation'))
  .catch(err => logError(err));
