/* eslint-disable react/prop-types */
import React from 'react';

import { formatPhoneNumber, generateWhatsAppLink } from '../../../utils/mobileHelpers';
import styles from './SLTableCellFormatter.module.scss';

const SLTableCellMobileFormatter = () => ({ row }) => {
  const { mobile_prefix, mobile_number } = row.original;
  if (!mobile_number) return null;

  const whatsappLink = generateWhatsAppLink(mobile_prefix, mobile_number);
  const formattedNumber = formatPhoneNumber(mobile_prefix, mobile_number, false);

  return (
    <a
      className={styles.link}
      href={whatsappLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      {formattedNumber || mobile_number}
      <span style={{ marginLeft: '5px' }}>
        <i className="whatsapp icon" />
      </span>
    </a>
  );
};

export default SLTableCellMobileFormatter;
