import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { FormFieldDateTimeWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormStepTwo.module.scss';

const pronounsOptionsSelector = state => enumOptionsSelector(state, 'pronouns_type');

const RegisterAuditorFormStepTwo = () => {
  const { values } = useFormikContext();
  const pronounsOptions = useSelector(pronounsOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));

  return (
    <FormLayout subtitle="1 of 3" title="Basics details">
      <span className={styles.titleQuestion}>
        {'Your must submit your full name as on your formal ID'}
      </span>
      <FormFieldWrapper
        required
        name="first_name"
        placeholder="First Name"
      />
      <FormFieldWrapper
        name="middle_name"
        placeholder="Middle Name"
      />
      <FormFieldWrapper
        required
        name="last_name"
        placeholder="Last Name"
      />

      <span className={styles.titleQuestion}>
        {'Please select your preferred pronouns:'}
      </span>
      <FormFieldSelectWrapper
        required
        name="preferred_pronouns"
        options={pronounsOptions}
        placeholder="Select an option"
      />
      {values.preferred_pronouns === 'Other' && (
        <FormFieldWrapper
          required
          name="other_pronoun"
          placeholder="Other pronoun"
        />)}

      <span className={styles.titleQuestion}>
        {'What\'s your date of birth?'}
      </span>
      <Form.Field width={16}>
        <FormFieldDateTimeWrapper
          className={styles.birthDateInput}
          date={false}
          format={DateTimeFormat.DAY_MONTH_YEAR}
          name="date_of_birth"
          placeholder="DD/MM/YYYY"
          time={false}
        />
        <div className={styles.titleQuestion}>
          Please enter your preferred name.
        </div>
        <div className={styles.note}>
          {'This is what we shall refer to you as should your application be successful This can aiso include shortened names (i.e. Thomas - Tom, Loretto - Lottie).'}
        </div>
        <div className={styles.note}>
          {'If you do not have a preferred name, please reinput your first and last nome as above'}
        </div>

        <FormFieldWrapper
          required
          name="preferred_first_name"
          placeholder="First Name"
        />
        <FormFieldWrapper
          required
          name="preferred_last_name"
          placeholder="Last Name"
        />

      </Form.Field>
    </FormLayout>
  );
};

export default RegisterAuditorFormStepTwo;
