/* eslint-disable you-dont-need-lodash-underscore/filter */
import _filter from 'lodash/filter';
import { createSelector } from 'reselect';

import { AuditingSteps } from '../../constants';

export const allAuditsSelector = state => state.audits;

export const auditSelector = createSelector(
  allAuditsSelector,
  (_, key) => key,
  (audits, key) => audits[key],
);

export const originalAuditSelector = createSelector(
  auditSelector,
  audit => audit?.original,
);

export const auditStepSelector = createSelector(
  auditSelector,
  audit => audit?.step,
);

export const auditAnswersSelector = createSelector(
  auditSelector,
  audit => audit?.answers,
);

export const auditCurrentQuestionIdSelector = createSelector(
  auditSelector,
  audit => audit?.currentQuestionId,
);

export const auditExtraDetailsSelector = createSelector(
  auditSelector,
  audit => audit?.extraDetails,
);

export const finishedAuditsSelector = createSelector(
  allAuditsSelector,
  audits => _filter(audits, { step: AuditingSteps.OFFLINE_FINISH }),
);

export const offlineAuditsSelector = createSelector(
  allAuditsSelector,
  audits => _filter(audits, audit => !audit.step || audit.step !== 'submitted'),
);
