import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { auditorsAPI, auditsAPI } from '../../../../api';
import { mapFeatureEnabledSelector } from '../../../../state/auditTableView/selectors';
import CustomError from '../../../../utils/customError';
import { parse } from '../../../../utils/queryString';
import { createIconLayer, LAYER_ID } from '../helpers';
import AuditorMapControl from '../MapControls/AuditorMapControl.component';
import MapRenderer from '../MapRenderer/MapRenderer.component';
import MapTooltip from '../MapTooltip/MapTooltip.component';
import styles from './AuditsMap.module.scss';

const AuditsMap = () => {
  const { search } = useLocation();
  const queryParams = parse(search);
  const mapView = useSelector(mapFeatureEnabledSelector);
  const retrieveFilterParams = query => {
    const { limit, page, sort, order, ...filters } = query;
    return filters;
  };
  const filterParams = retrieveFilterParams(queryParams);

  const [mapAudits, setMapAudits] = useState([]);
  const [mapAuditors, setMapAuditors] = useState([]);
  const [showAuditors, setShowAuditors] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(null);

  const { data: auditsMapResp, error: auditsMapError } = useQuery(
    ['auditsMap', filterParams],
    () => auditsAPI.fetchAllForMap(filterParams),
    {
      enabled: mapView,
    },
  );

  const { data: auditorsMapResp, error: auditorsMapError } = useQuery(
    'fetchAuditorsForMap',
    auditorsAPI.fetchAllForMap,
    {
      enabled: mapView && showAuditors,
      staleTime: 15 * 60 * 1000,
    },
  );

  const { data: auditorTooltip } = useQuery(
    ['auditorTooltip', hoverInfo?.object?.id],
    () => auditorsAPI.fetchAuditorDetails({ id: hoverInfo?.object?.id }),
    {
      enabled: !!hoverInfo?.object?.id && hoverInfo.layerId === LAYER_ID.AUDITORS,
    },
  );

  // TODO: This is commented out because the API is not available yet
  //   const { data: auditTooltip } = useQuery(
  //   ['auditTooltip', hoverInfo?.object?.id],
  //   () => auditorsAPI.fetchAuditorDetails({ id: hoverInfo?.object?.id }),
  //   {
  //     enabled: !!hoverInfo?.object?.id && hoverInfo.layerId === LAYER_ID.AUDITS,
  //   },
  // );

  const removeDuplicateCoordinates = audits => {
    const seen = new Map();

    return audits.filter(audit => {
      if (!audit.site_coordinates) return false;

      const { lat, lng } = audit.site_coordinates;
      const coordKey = `${lat.toFixed(6)},${lng.toFixed(6)}`;

      if (seen.has(coordKey)) {
        return false;
      }

      seen.set(coordKey, true);
      return true;
    });
  };

  useEffect(() => {
    if (auditsMapError) {
      Sentry.captureException(new CustomError(auditsMapError));
      alert.error(`Error fetching audits for Map feature: ${auditsMapError.message}`);
      setMapAudits([]);
    }
    if (auditsMapResp) {
      const removedDuplicates = removeDuplicateCoordinates(auditsMapResp.audits);
      setMapAudits(removedDuplicates);
    }
  }, [auditsMapResp, auditsMapError]);

  useEffect(() => {
    if (auditorsMapError) {
      Sentry.captureException(new CustomError(auditorsMapError));
      alert.error(`Error fetching auditors for Map feature: ${auditorsMapError.message}`);
      setMapAuditors([]);
    }
    if (auditorsMapResp) {
      setMapAuditors(auditorsMapResp.items.filter(d => d.home_coordinates));
    }
  }, [auditorsMapError, auditorsMapResp]);

  const handleHover = useCallback(info => {
    if (info.object) {
      setHoverInfo({
        object: info.object,
        x: info.x,
        y: info.y,
        layerId: info.layer.id,
      });
    } else {
      setHoverInfo(null);
    }
  }, []);

  const auditsLayer = useMemo(
    () => createIconLayer({ id: LAYER_ID.AUDITS, items: mapAudits, layerProps: { onHover: handleHover } }),
    [handleHover, mapAudits],
  );
  const auditorsLayer = useMemo(
    () => createIconLayer({
      id: LAYER_ID.AUDITORS,
      items: mapAuditors,
      layerProps: {
        getPosition: a => [a.home_coordinates.lng, a.home_coordinates.lat, 0],
        getColor: () => [255, 0, 0, 255],
        visible: showAuditors,
        onHover: handleHover,
      },
    }),
    [mapAuditors, showAuditors, handleHover],
  );

  const layers = useMemo(() => {
    const activeLayers = [];
    if (auditsLayer) activeLayers.push(auditsLayer);
    if (auditorsLayer) activeLayers.push(auditorsLayer);
    return activeLayers;
  }, [auditsLayer, auditorsLayer]);

  return (
    <div className={styles.mapContainer}>
      <MapRenderer layers={layers}>
        <AuditorMapControl setShowAuditors={setShowAuditors} showAuditors={showAuditors} />
        {auditorTooltip && (
          <MapTooltip
            data={{
              ...hoverInfo,
              ...auditorTooltip,
            }}
          />
        )}
      </MapRenderer>
    </div>
  );
};

export default AuditsMap;
