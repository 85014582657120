import { useFeatureFlag } from 'configcat-react';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { useUserRole } from '../../../../../hooks';
import { FieldScope, fieldVisibility, TableViewActions } from '../../../../../rolePermissionMatrix';
import { toggleMapView } from '../../../../../state/auditTableView/actionCreators';
import { mapFeatureEnabledSelector } from '../../../../../state/auditTableView/selectors';
import { SLHeader } from '../../../../common';
import Toggle from '../../../../common/Toggle/Toggle.component';
import EntityExportMenu from '../../../Entity/EntityTableView/EntityTableHeader/EntityExportMenu/EntityExportMenu.component';
import styles from './AuditsTableHeaderAdminRole.module.scss';

const AuditsTableHeaderAdminRole = ({ count, isFilterVisible, setFilterVisible }) => {
  const { entity } = useParams();
  const dispatch = useDispatch();
  const role = useUserRole();

  const mapView = useSelector(mapFeatureEnabledSelector);
  const toggleView = () => dispatch(toggleMapView());
  const { value: displayMapSwitch } = useFeatureFlag('newMaps');

  const DATA_CY = `${entity}-table-header`;

  return (
    <SLHeader dataCy={DATA_CY} subtitle={count ? `${count} Results Found` : ''} title={entity}>
      {displayMapSwitch && (
        <div className={styles.toggleContainer}>
          <Toggle checked={mapView} label="Map View" offLabel="Table View" onChange={toggleView} />
        </div>
      )}
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.IMPORT) ? (
        <Link data-cy={`${DATA_CY}-upload-link`} to={`/${entity}/upload`}>
          <span className={styles.upload}>
            {`Upload ${entity}`}
          </span>
        </Link>
      ) : null}
      {fieldVisibility({ entity, role, scope: FieldScope.TABLE_VIEW_ACTIONS })(TableViewActions.EXPORT) ? (
        <EntityExportMenu
          canAddAndEdit={fieldVisibility({
            entity,
            role,
            scope: FieldScope.TABLE_VIEW_ACTIONS,
          })(TableViewActions.EXPORT_EDIT_TEMPLATE)}
        />
      ) : null}
      <div
        className={isFilterVisible ? styles.toggleIconOpen : styles.toggleIconClosed}
        data-cy={`${DATA_CY}-filter-toggle`}
        onClick={() => setFilterVisible(!isFilterVisible)}
      />
    </SLHeader>
  );
};

AuditsTableHeaderAdminRole.defaultProps = {
  count: null,
  isFilterVisible: false,
  setFilterVisible: null,
};

AuditsTableHeaderAdminRole.propTypes = {
  count: PropTypes.number,
  isFilterVisible: PropTypes.bool,
  setFilterVisible: PropTypes.func,
};

export default AuditsTableHeaderAdminRole;
