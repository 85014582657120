/* eslint-disable you-dont-need-lodash-underscore/get */
/* eslint-disable react/prop-types */
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Image } from 'semantic-ui-react';

import styles from './SLTableCellFormatter.module.scss';

const LinkWrapper = ({ children, link }) => (
  <Link className={styles.link} to={link}>
    {children}
  </Link>
);

LinkWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  link: PropTypes.string.isRequired,
};

const SLTableCellFormatter = ({
  avatarAttribute = null,
  button = false,
  textClassName = '',
  linkFormat = null,
  textFormat = null,
} = {}) => ({ cell }) => {
  const link = linkFormat
    ? linkFormat.replace(/#((\w|\.)*)/gi, (_match, groupValue) => _get(cell.row.original, groupValue))
    : null;

  const text = textFormat
    ? textFormat.replace(/#((\w|\.)*)/gi, (_match, groupValue) => _get(cell.row.original, groupValue) || '')
    : cell.value;

  const content = button ? (
    <Button primary>
      {text}
    </Button>
  ) : (
    <span className={textClassName}>
      {text}
    </span>
  );

  return (
    <>
      {avatarAttribute && (
        <Image
          rounded
          className={styles.image}
          spaced="right"
          src={cell.row.original[avatarAttribute]}
        />
      )}
      {link ? (
        <LinkWrapper link={link}>
          {content}
        </LinkWrapper>
      ) : (
        content
      )}
    </>
  );
};

export default SLTableCellFormatter;
