import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { Redirect, Route as ReactRouterDomRoute, Router, Switch } from 'react-router-dom';

import { Fallback } from '../components/common';
import DeveloperPanel from '../components/pages/DeveloperPanel/DeveloperPanel.component';
import history from '../history';
import AppRoute from './routes';

// Enable Sentry tracing on routes
const Route = Sentry.withSentryRouting(ReactRouterDomRoute);

const DeveloperRouter = () => (
  <Router history={history}>
    <Suspense fallback={<Fallback />}>
      <Switch>
        <Route
          exact
          component={DeveloperPanel}
          path={AppRoute.DASHBOARD.path}
        />
        <Redirect to={AppRoute.DASHBOARD.path} />
      </Switch>
    </Suspense>
  </Router>
);

export default DeveloperRouter;
