import download from 'downloadjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';

import { auditsAPI, entitiesAPI, errorAPI } from '../../../../api';
import { AuditStatus, CustomEventType, Entity, UserRole } from '../../../../constants';
import isMobile from '../../../../utils/isMobile';
import { parse } from '../../../../utils/queryString';
import { FormFieldSelectWrapper } from '../../../common';
import { RoleChecker } from '../../../layout';
import AuditsRouteModal from '../AuditsRouteModal/AuditsRouteModal.component';
import AuditsTableBulkOperationsModal from '../AuditsTableBulkOperationsModal/AuditsTableBulkOperationsModal.component';
import AssignAuditorPayModal from '../AuditsTableBulkOperationsModals/AssignAuditorAndPayModal.component';
import styles from './AuditsTableBulkOperations.module.scss';
import { showSorting, sortingOptions } from './helpers';

const DATA_CY = 'audits-table-bulk-operations';

const AuditsTableBulkOperations = ({
  audits,
  refetch,
  selectedAudits,
  setOfflineData,
  onBulkEnd,
  onBulkStart,
}) => {
  const alert = useAlert();
  const location = useLocation();

  const currentQuery = parse(location.search);

  const [modalProps, setModalProps] = useState({
    open: false,
    visibleFields: [],
  });

  const [auditorPayModalProps, setAuditorPayModalProps] = useState({
    open: false,
  });

  const [modalAuditorRoute, setModalAuditorRoute] = useState({
    open: false,
  });

  const isApprovedAudits = selectedAudits.some(audit => audit.status === AuditStatus.APPROVED);

  const deleteRoute = () => {
    const event = new CustomEvent(CustomEventType.MAP_ROUTE_REMOVE);
    window.dispatchEvent(event);
  };

  const handleModalProps = (open, visibleFields = []) => {
    setModalProps({
      open,
      visibleFields,
    });
    deleteRoute();
  };

  const handleAuditorPayModalProps = open => {
    if (!isApprovedAudits) {
      setAuditorPayModalProps({
        open,
      });
    } else {
      alert.error('Error updating audit pay, one or more audits selected are in a status of approved');
    }
    deleteRoute();
  };

  const handleBulkEditModalProps = () => {
    if (!isApprovedAudits) {
      handleModalProps(
        true,
        ['visit_info', 'auditor_instructions', 'item_to_order', 'deadline_date', 'auditor_pay_per_audit'],
      );
    } else {
      alert.error('Error updating audit pay, one or more audits selected are in a status of approved');
    }
  };

  const handleRouteModalProps = open => {
    setModalAuditorRoute({
      open,
    });
    deleteRoute();
  };

  const { mutate } = useMutation(entitiesAPI.bulk, {
    onSuccess: () => {
      alert.success('Bulk operation succeeded');
      refetch();
    },
    onError: error => {
      alert.error(`Error in bulk operation: ${error.message}`);
    },
  });

  const handleBulkOperation = values => {
    const payload = selectedAudits.map(audit => ({
      id: audit.id,
      ...values,
    }));
    mutate({ entity: Entity.AUDITS, payload });
  };

  const handleBulkAssignAuditorAndPay = payload => {
    mutate({ entity: Entity.AUDITS, payload });
  };

  const handleUnassignAuditor = () => {
    const payload = selectedAudits.map(audit => ({
      id: audit.id,
      auditor_id: null,
    }));
    mutate({ entity: Entity.AUDITS, payload });
  };

  const handleBulkPDF = () => {
    const payload = selectedAudits.map(audit => ({
      id: audit.id,
    }));

    onBulkStart();

    auditsAPI.bulkPdf({ payload })
      .then(data => {
        const reader = new FileReader();
        reader.readAsBinaryString(data);
        reader.onload = () => {
          download(reader.result, 'reports.zip');
        };
      })
      .catch(error => {
        alert.error(`Error exporting to PDF: ${error.message}`);
        errorAPI.sendError(error.message, '');
      })
      .finally(onBulkEnd);
  };

  return (
    <div className={styles.root} data-cy={DATA_CY}>
      <RoleChecker allowedRoles={[UserRole.DATA, UserRole.AREA_MANAGER, UserRole.CLIENT_SERVICES]}>
        <Button
          secondary
          data-cy={`${DATA_CY}-assign-auditor`}
          disabled={selectedAudits.length === 0}
          onClick={() => handleModalProps(true, ['auditor_id', 'deadline_date'])}
        >
          {'Assign Auditor'}
        </Button>

        <Button
          secondary
          data-cy={`${DATA_CY}-assign-auditor-and-pay`}
          disabled={selectedAudits.length === 0}
          onClick={() => handleAuditorPayModalProps(true)}
        >
          {'Assign Auditor + Pay'}
        </Button>

        <Button
          secondary
          data-cy={`${DATA_CY}-bulk-edit`}
          disabled={selectedAudits.length === 0}
          onClick={() => handleBulkEditModalProps()}
        >
          {'Bulk Edit'}
        </Button>

        <Button
          secondary
          data-cy={`${DATA_CY}-set-deadline-date`}
          disabled={selectedAudits.length === 0}
          onClick={() => handleModalProps(true, ['deadline_date'])}
        >
          {'Set Deadline Date'}
        </Button>

        <Button
          secondary
          data-cy={`${DATA_CY}-unassign-auditor`}
          disabled={selectedAudits.length === 0}
          onClick={handleUnassignAuditor}
        >
          {'Unassign Auditor'}
        </Button>
      </RoleChecker>

      <RoleChecker allowedRoles={[UserRole.DATA, UserRole.AREA_MANAGER, UserRole.CLIENT_SERVICES, UserRole.CLIENT]}>
        <Button
          secondary
          data-cy={`${DATA_CY}-bulk-pdf`}
          disabled={selectedAudits.length === 0}
          onClick={handleBulkPDF}
        >
          {'Bulk PDF'}
        </Button>
      </RoleChecker>

      <RoleChecker allowedRoles={[UserRole.AUDITOR]}>
        {(isMobile && showSorting(currentQuery.status))
          && <Formik enableReinitialize initialValues={{ sort_by: '' }} >
            {({ handleSubmit, values }) => {
              const sortedAudits = audits.sort((a, b) => {
                if (values.sort_by === 'client_name') {
                  return a.site_name.localeCompare(b.site_name);
                }
                if (values.sort_by === 'deadline_date') {
                  return new Date(a.deadline_date) - new Date(b.deadline_date);
                }
                if (values.sort_by === 'site_post_code') {
                  return a.site_post_code.localeCompare(b.site_post_code);
                }
                if (values.sort_by === 'submitted_date_oldest') {
                  return new Date(a.submitted_date) - new Date(b.submitted_date);
                }
                if (values.sort_by === 'submitted_date_newest') {
                  return new Date(b.submitted_date) - new Date(a.submitted_date);
                }
                return [];
              });
              setOfflineData([...sortedAudits]);

              return (
                <Form data-cy={DATA_CY}>
                  <Form.Group widths={4}>
                    <FormFieldSelectWrapper
                      label="Sort by"
                      name="sort_by"
                      options={sortingOptions(currentQuery.status)}
                      onChange={handleSubmit}
                    />
                  </Form.Group>
                </Form>
              );
            }}

          </Formik>}
        <Button
          secondary
          data-cy={`${DATA_CY}-calculate-route`}
          disabled={selectedAudits.length === 0}
          onClick={() => handleRouteModalProps(true)}
        >
          {'Route'}
        </Button>
      </RoleChecker>

      <AssignAuditorPayModal
        open={auditorPayModalProps.open}
        selectedAudits={selectedAudits}
        onCancel={() => { handleAuditorPayModalProps(false); }}
        onConfirm={values => {
          handleAuditorPayModalProps(false);
          handleBulkAssignAuditorAndPay(values);
        }}
      />

      <AuditsTableBulkOperationsModal
        open={modalProps.open}
        selectedAudits={selectedAudits}
        visibleFields={modalProps.visibleFields}
        onCancel={() => { handleModalProps(false); }}
        onConfirm={values => {
          handleModalProps(false);
          handleBulkOperation(values);
        }}
      />

      <AuditsRouteModal
        open={modalAuditorRoute.open}
        selectedAudits={selectedAudits}
        onCancel={() => { handleRouteModalProps(false); }}
      />
    </div>
  );
};

AuditsTableBulkOperations.propTypes = {
  audits: PropTypes.arrayOf(PropTypes.object).isRequired,
  refetch: PropTypes.func.isRequired,
  selectedAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOfflineData: PropTypes.func.isRequired,
  onBulkEnd: PropTypes.func.isRequired,
  onBulkStart: PropTypes.func.isRequired,
};

export default AuditsTableBulkOperations;
