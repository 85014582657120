/* eslint-disable import/prefer-default-export */
import { StatusCodes } from 'http-status-codes';

import { appConfig } from '../config';
import axios from './axiosInstance';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const create = async ({ entity, payload }) => axios.post(
    `${appConfig.backendUrl}/api/v2/${entity}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
)
  .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity}`))
  .catch(logError);
