import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { formatPhoneNumber } from '../../../../../utils/mobileHelpers';
import { FormFieldCheckboxWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import { countryPrefixes } from '../../RegisterAuditorFormWrapper/helpers';
import styles from './RegisterAuditorFormStepTen.module.scss';

const accessToRelationshipOptionsSelector = state => enumOptionsSelector(state, 'relationship_type');
const transportAvailableOptionSelector = state => enumOptionsSelector(state, 'transport_available_type');

const RegisterAuditorFormStepTen = () => {
  const { values, setFieldValue } = useFormikContext();

  const accessToRelationshipOptions = useSelector(accessToRelationshipOptionsSelector).map(option => ({
    value: option.value,
    text: option.value,
  }));
  const transportAvailableOption = useSelector(transportAvailableOptionSelector);

  const handlePhoneChange = value => {
    const rawValue = value;

    setFieldValue('mobile_number_raw', rawValue);

    const formattedNumber = formatPhoneNumber(values.mobile_prefix, rawValue);
    setFieldValue('mobile_number', formattedNumber);
  };

  return (
    <FormLayout subtitle="3 of 3" title="Extra information">
      <span className={styles.titleQuestion}>
        {'What\'s your number?'}
      </span>
      <FormFieldSelectWrapper
        required
        name="mobile_prefix"
        options={countryPrefixes.map(country => ({
          value: `${country.code}-${country.dialCode}`,
          text: `${country.flag} ${country.name} (${country.dialCode})`,
        }))}
        placeholder="Select country code"
      />
      <FormFieldWrapper required name="mobile_number" placeholder="Mobile number" onChange={handlePhoneChange} />
      <span className={styles.titleQuestion}>
        {'Emergency contact'}
      </span>
      <span className={styles.italicTitleQuestion}>
        {'Your emergency contact would only be contacted in emergencies regarding your safety or wellbeing concerns.'}
      </span>
      <FormFieldWrapper required name="emergency_phone" placeholder="Emergency contact phone number" />
      <FormFieldWrapper required name="emergency_name" placeholder="Emergency contact name" />
      <span className={styles.titleQuestion}>
        {'Please select their relationship to you'}
      </span>
      <FormFieldSelectWrapper
        required
        name="emergency_relationship"
        options={accessToRelationshipOptions}
        placeholder="Select an option"
      />
      <span className={styles.titleQuestion}>
        {'Transport available to you'}
      </span>
      <FormFieldSelectWrapper
        required
        name="transport_available"
        options={transportAvailableOption}
        placeholder="Select an option"
      />

      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I would like to receive email communication from Serve Legal outside of essential employee information."
        name="email_communication"
      />
      <FormFieldCheckboxWrapper
        labelAfterCheckbox
        label="I would like to continue being part of the Serve Legal community and to be contacted with new types of work once my employment has ended."
        name="servelegal_community"
      />
    </FormLayout>
  );
};

export default RegisterAuditorFormStepTen;
