import PropTypes from 'prop-types';
import React from 'react';

import Toggle from '../../../common/Toggle/Toggle.component';
import styles from './AuditorMapControl.module.scss';

const AuditorMapControl = ({ showAuditors, setShowAuditors }) => (
  <div className={styles.container}>
    <div className={styles.sliderContainer}>
      <span className={styles.mapControlLabel}>
Auditors
      </span>
      <Toggle checked={showAuditors} onChange={() => setShowAuditors(!showAuditors)} />
    </div>
  </div>

);

export default AuditorMapControl;

AuditorMapControl.propTypes = {
  setShowAuditors: PropTypes.func,
  showAuditors: PropTypes.bool,
};

AuditorMapControl.defaultProps = {
  setShowAuditors: () => {},
  showAuditors: false,
};
