import React from 'react';

import { Entity } from '../../../../constants';
import AuditorDetails from '../../Auditors/AuditorDetails/AuditorDetails.component';
import AuditorDetailsHeader from '../../Auditors/AuditorDetailsHeader/AuditorDetailsHeader.component';
import AuditDetails from '../../Audits/AuditDetails/AuditDetails.component';
import AuditDetailsHeader from '../../Audits/AuditDetailsHeader/AuditDetailsHeader.component';
import ClientDetails from '../../Clients/ClientDetails/ClientDetails.component';
import LogicDetails from '../../Logics/LogicDetails/LogicDetails.component';
import LogicDetailsSidebar from '../../Logics/LogicDetailsSidebar/LogicDetailsSidebar.component';
import OrderDetails from '../../Orders/OrderDetails/OrderDetails.component';
import PatchesMap from '../../Patches/PatchesMap/PatchesMap.component';
import PatchesSidebar from '../../Patches/PatchesSidebar/PatchesSidebar.component';
import SiteDetails from '../../Sites/SiteDetails/SiteDetails.component';
import TaxonomyDetails from '../../Taxonomy/TaxonomyDetails/TaxonomyDetails.component';
import UserDetails from '../../Users/UserDetails/UserDetails.component';
import EntityDetailsHeader from './EntityDetailsHeader/EntityDetailsHeader.component';

export const body = ({ entity }) => {
  const entityBody = {
    [Entity.AUDITORS]: AuditorDetails,
    [Entity.AUDITS]: AuditDetails,
    [Entity.CLIENTS]: ClientDetails,
    [Entity.LOGICS]: LogicDetails,
    [Entity.ORDERS]: OrderDetails,
    [Entity.PATCHES]: PatchesMap,
    [Entity.SITES]: SiteDetails,
    [Entity.TAXONOMY]: TaxonomyDetails,
    [Entity.USERS]: UserDetails,
  };

  return entityBody[entity] || (() => (
    <span>
      DETAILS
    </span>
  ));
};

export const bodyProps = ({ entity, entityId, data, submitFormRef, save, saveTaxonomy, refetch }) => {
  const props = {
    [Entity.AUDITORS]: { data, entity, entityId, submitFormRef, refetch },
    [Entity.AUDITS]: { data, entity, entityId, submitFormRef, refetch },
    [Entity.CLIENTS]: { data, entity, entityId, submitFormRef, onSubmit: save },
    [Entity.LOGICS]: { data, entity, entityId, submitFormRef, onSubmit: save },
    [Entity.ORDERS]: { data, entity, entityId, submitFormRef, onSubmit: save },
    [Entity.PATCHES]: { data, entity, entityId, submitFormRef, onSubmit: save },
    [Entity.SITES]: { data, entity, entityId, submitFormRef, onSubmit: save },
    [Entity.TAXONOMY]: { data, entity, entityId, submitFormRef, onSubmit: saveTaxonomy, refetch },
    [Entity.USERS]: { data, entity, entityId, submitFormRef, onSubmit: save, refetch },
  };

  return props[entity] || {};
};

export const header = ({ entity, mode }) => {
  const entityHeader = {
    [Entity.AUDITS]: AuditDetailsHeader,
    [Entity.AUDITORS]: (mode === 'view' ? AuditorDetailsHeader : EntityDetailsHeader),
  };

  return entityHeader[entity] || EntityDetailsHeader;
};

export const headerProps = ({ data, entity, submitFormRef, cancel, edit, mode, refetch }) => {
  const props = {
    [Entity.AUDITS]: { data, onCancel: cancel, onSave: () => submitFormRef?.current?.click(), refetch },
    [Entity.AUDITORS]: (mode === 'view'
      ? { data, refetch, onCancel: cancel, onEdit: edit }
      : { data, onCancel: cancel, onSave: () => submitFormRef?.current?.click() }
    ),
    [Entity.TAXONOMY]: data?.vocabulary === 'documents'
      ? { data, titleProp: 'vocabulary', onCancel: cancel }
      : { data, titleProp: 'vocabulary', onCancel: cancel, onSave: () => submitFormRef?.current?.click() },
  };

  return props[entity] || { data, onCancel: cancel, onSave: () => submitFormRef?.current?.click() };
};

export const sidebar = entity => {
  const entitySidebar = {
    [Entity.LOGICS]: LogicDetailsSidebar,
    [Entity.PATCHES]: PatchesSidebar,
  };

  return entitySidebar[entity] || null;
};
