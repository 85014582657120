import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../MapTooltip.module.scss';

const AuditContent = ({ content }) => {
  const { deadline_date } = content;

  return (
    <div className={styles.tooltipContent}>
      <p>
        <strong>
Item to Order:
        </strong>
XXXXXX
      </p>
      <p>
        <strong>
Order Deadline:
        </strong>
        {deadline_date && format(new Date(deadline_date), 'dd/MM/yyyy')}
      </p>
      <p className={styles.auditorsContainer}>
        <strong>
Last 3 Auditors:
        </strong>
        <div className={styles.auditors}>
          <span className={styles.success}>
✔ Lewis Shipp 26/01/25
          </span>
          <span className={styles.success}>
✔ Damon Heta 18/12/24
          </span>
          <span className={styles.error}>
✘ Joshua Wood 04/11/24
          </span>
        </div>
      </p>
    </div>
  );
};

export default AuditContent;

AuditContent.propTypes = {
  content: PropTypes.shape({
    deadline_date: PropTypes.string,
  }).isRequired,
};
