/* eslint-disable no-alert */
import { accountAPI, auditorsAPI } from '../api';
import {
  logError,
} from '../api/utils';
import persistence from '../persistence';

const minToMls = min => min * 60 * 1000;

let getRoleIntervalId;
let geolocationIntervalId;
let role;
const getRoleInterval = minToMls(5);

const getUserConfig = () => accountAPI.fetchAuditorAccount();

const getRole = async () => {
  const cookieStorage = persistence('cookieStorage');
  // Stored al global on this scope:
  role = cookieStorage.getRole();
  return role;
};

const errorGGL = err => {
  logError(err);
};

const successGGL = async pos => {
  const { latitude, longitude } = pos.coords;
  auditorsAPI.updateGeolocation({
    lat: latitude,
    lng: longitude,
  });
};

const optionsGGL = {
  enableHighAccuracy: true,
  timeout: minToMls(1),
  maximumAge: 0,
};

export const getGeolocationForAuditors = async () => {
  await getRole();
  if (role === 'auditor') {
    getGeolocation(successGGL);
  } else if (!role) {
    // Role has been removed. Start again.
    startAutolocation();
  }
};

const startUpdateLocationInterval = async () => {
  if (geolocationIntervalId) {
    window.clearInterval(geolocationIntervalId);
    geolocationIntervalId = undefined;
  }
  const config = await getUserConfig();
  if (!config.auto_geolocation_interval) {
    return;
  }
  geolocationIntervalId = window.setInterval(
    getGeolocationForAuditors,
    minToMls(config.auto_geolocation_interval),
  );
};

const checkForRole = async () => {
  await getRole();
  if (role === 'auditor') {
    if (getRoleIntervalId) {
      window.clearInterval(getRoleIntervalId);
      getRoleIntervalId = undefined;
    }
    if ('geolocation' in navigator) {
      startUpdateLocationInterval();
    } else {
      alert('Your browser has not implemented geolocation feature');
    }
  } else {
    window.clearInterval(geolocationIntervalId);
    geolocationIntervalId = undefined;
  }
};

export function startAutolocation() {
  if (getRoleIntervalId) {
    window.clearInterval(getRoleIntervalId);
    getRoleIntervalId = undefined;
  } else if (geolocationIntervalId) {
    window.clearInterval(geolocationIntervalId);
    geolocationIntervalId = undefined;
  }
  getRoleIntervalId = window.setInterval(checkForRole, getRoleInterval);
}

export function getGeolocation(callback = successGGL, errorCallback = errorGGL) {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(callback, errorCallback, optionsGGL);
  } else {
    alert('Your browser has not implemented geolocation feature');
  }
}

export const getReverseGeocodingData = async ({
  latitude,
  longitude,
}, geocodeExtractProps = []) => {
  // TODO: get google map from redux state once initiated as singleton
  const latLng = new window.google.maps.LatLng(latitude, longitude);

  const geocoder = new window.google.maps.Geocoder();
  const { results } = await geocoder.geocode({ latLng }, response => response);

  if (geocodeExtractProps.length) {
    const geocoded = geocodeExtractProps.reduce((acc, {
      key,
      label,
      fn,
    }) => {
      const value = results.find(comp => comp.types.includes(key));
      if (value) {
        if (fn) {
          acc[label] = fn(value.formatted_address);
        } else acc[label] = value.formatted_address;
      }

      return acc;
    }, {});

    if (geocoded) return geocoded;
  }

  return results;
};
