import { useFeatureFlag } from 'configcat-react';
import { Formik } from 'formik';
import _debounce from 'lodash/debounce';
import _pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';

import { DEBOUNCE_MS, DefaultSurveyResultType } from '../../../../../constants';
import parseEnumOptions from '../../../../../utils/parseEnumOptions';
import { FormFieldDateTimeWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import {
  auditFilterDefaultValues,
  auditFilterValidationSchema,
} from '../../helpers';

const DATA_CY = 'audits-table-filter';

const AuditsTableFilterClientRole = ({ values: initialValues, onChange }) => {
  const { value: auditsFilterButton } = useFeatureFlag('auditsFilterButton', false);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        status: initialValues.status ? [initialValues.status].flat() : [],
        order_schedule_type: initialValues.order_schedule_type ? [initialValues.order_schedule_type].flat() : [],
      }}
      validationSchema={auditFilterValidationSchema}
      onSubmit={values => {
        const finalValues = _pickBy(values, value => value !== null && value !== undefined);
        if (finalValues.site_post_code && typeof finalValues.site_post_code === 'string') {
          finalValues.site_post_code = finalValues.site_post_code.split(',').map(code => code.trim());
        }
        onChange(finalValues);
      }}
    >
      {({ handleSubmit }) => {
        const debounceSubmit = _debounce(handleSubmit, DEBOUNCE_MS);

        const handleFieldChange = () => {
          debounceSubmit();
        };

        return (
          <Form inverted data-cy={DATA_CY}>
            <Grid padded columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <FormFieldDateTimeWrapper
                    clearable
                    label="Audit Date - From"
                    name="date_of_visit_from"
                    time={false}
                    {...(!auditsFilterButton && { onChange: handleSubmit })}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormFieldDateTimeWrapper
                    clearable
                    label="To"
                    name="date_of_visit_to"
                    time={false}
                    {...(!auditsFilterButton && { onChange: handleSubmit })}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormFieldSelectWrapper
                    clearable
                    label="Result"
                    name="survey_result"
                    options={parseEnumOptions(DefaultSurveyResultType)}
                    {...(!auditsFilterButton && { onChange: handleSubmit })}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <FormFieldWrapper
                    label="Site Code"
                    name="site_code"
                    {...(!auditsFilterButton && { onChange: handleFieldChange })}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormFieldWrapper
                    label="Site"
                    name="site_name"
                    {...(!auditsFilterButton && { onChange: handleFieldChange })}
                  />
                </Grid.Column>
                <Grid.Column>
                  <FormFieldWrapper
                    label="Post Code (split values with commas)"
                    name="site_post_code"
                    {...(!auditsFilterButton && { onChange: handleFieldChange })}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <FormFieldWrapper
                    label="Audit ID"
                    name="audit_internal_id"
                    {...(!auditsFilterButton && { onChange: handleFieldChange })}
                  />
                </Grid.Column>
              </Grid.Row>
              {auditsFilterButton && (
                <Grid.Row>
                  <Grid.Column></Grid.Column>
                  <Grid.Column></Grid.Column>
                  <Grid.Column textAlign={'right'}>
                    <Button
                      icon
                      labelPosition={'right'}
                      onClick={handleSubmit}
                    >
                      Apply Filters
                      <Icon name={'search'} />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AuditsTableFilterClientRole.defaultProps = {
  values: auditFilterDefaultValues,
};

AuditsTableFilterClientRole.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    auditor_name: PropTypes.string,
    client_name: PropTypes.string,
    order_deadline_from: PropTypes.string,
    order_deadline_to: PropTypes.string,
    order_internal_id: PropTypes.string,
    order_schedule_type: PropTypes.string,
    site_name: PropTypes.string,
    site_post_code: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default AuditsTableFilterClientRole;
