import { Formik, useFormikContext } from 'formik';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Label } from 'semantic-ui-react';

import { multipartAPI } from '../../../../../api';
import { AuditorStatus, Entity } from '../../../../../constants';
import { useUserRole } from '../../../../../hooks';
import { fetchUserInfo } from '../../../../../state/constants/actionCreators';
import { enumOptionsSelector } from '../../../../../state/constants/selectors';
import { generateWhatsAppLink, getPhoneNumberWithDialCode } from '../../../../../utils/mobileHelpers';
import timeDiffToString from '../../../../../utils/timeDiffToString';
import {
  FormFieldCheckboxWrapper,
  FormFieldWrapper,
  FullScreenImageModalV2,
  WaitPlaceholder,
} from '../../../../common';
import { getFormattedDate } from '../../helpers';
import styles from './AuditorDetailsProfile.module.scss';

const transportAvailableTypeSelector = state => enumOptionsSelector(state, 'transport_available_type');

const AutoSubmit = () => {
  const { values, initialValues, submitForm } = useFormikContext();
  const { right_to_work, briefed_for_ssl, briefed_for_narv } = values;
  const {
    right_to_work: initialRightsToWork,
    briefed_for_ssl: initialBriefedForSSL,
    briefed_for_narv: initialBriefedForNARV,
  } = initialValues;
  const initialRights = {
    right_to_work: initialRightsToWork,
    briefed_for_ssl: initialBriefedForSSL,
    briefed_for_narv: initialBriefedForNARV,
  };
  const currentRights = { right_to_work, briefed_for_ssl, briefed_for_narv };
  const rightsChanged = !_isEqual(initialRights, currentRights);
  useEffect(() => {
    if (rightsChanged) {
      submitForm();
    }
  }, [rightsChanged]);
  return null;
};

const AuditorDetailsProfile = ({ data, refetch, dataCy }) => {
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  const role = useUserRole();
  const transportAvailableOption = useSelector(transportAvailableTypeSelector);

  const { mutate: auditorMutate } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      alert.success('Auditor updated');
      dispatch(fetchUserInfo({ role }));
      refetch();
    },
    onError: error => {
      alert.error(`Error updating auditor: ${error.message}`);
    },
  });

  const onNotesChange = async values => {
    const formData = new FormData();
    const {
      identification,
      image,
      passport,
      visa,
      right_to_work_updated_at,
      right_to_work_updated_by,
      briefed_for_narv_updated_at,
      briefed_for_narv_updated_by,
      briefed_for_ssl_updated_at,
      briefed_for_ssl_updated_by,
      ...filteredValues
    } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    return auditorMutate({
      entity: Entity.AUDITORS,
      entityId: data.id,
      payload: formData,
    });
  };

  const onRightsChange = async values => {
    const formData = new FormData();
    const {
      identification,
      image,
      passport,
      visa,
      right_to_work_updated_at,
      right_to_work_updated_by,
      briefed_for_narv_updated_at,
      briefed_for_narv_updated_by,
      briefed_for_ssl_updated_at,
      briefed_for_ssl_updated_by,
      ...filteredValues
    } = values;

    Object.entries(filteredValues).forEach(([key, value]) => formData.append(key, value));

    return auditorMutate({
      entity: Entity.AUDITORS,
      entityId: data.id,
      payload: formData,
    });
  };

  const formattedTransportAvailable = transportAvailableOption
    ? transportAvailableOption.find(option => option.value === data.transport_available)?.text
    : '-';

  const AUDITOR_INFO = useMemo(
    () => [
      {
        title: 'Passport Name',
        testTitle: `${dataCy}-passport-name`,
        value: [data.first_name, data.middle_name, data.last_name].join(' '),
      },
      {
        title: 'Pronouns',
        testTitle: `${dataCy}-pronouns`,
        value: (data.preferred_pronouns === 'Other' && data.other_pronoun) || data.preferred_pronouns || '-',
      },
      {
        title: 'Age',
        testTitle: `${dataCy}-age`,
        value: `${timeDiffToString(data.date_of_birth, 'YM')}`,
      },
      {
        title: 'Transport available?',
        testTitle: `${dataCy}-transport`,
        value: formattedTransportAvailable || '-',
      },
      {
        title: 'Member for',
        testTitle: `${dataCy}-since`,
        value: `${timeDiffToString(data.created_at, 'YMD') || '0 days'}`,
      },
      {
        title: 'Last logged in',
        testTitle: `${dataCy}-logged`,
        value: timeDiffToString(data.last_access, 'YMD') || '-',
      },
      {
        title: 'Application date',
        testTitle: `${dataCy}-application-date`,
        value: data.application_date ? getFormattedDate(data.application_date) : '-',
        condition: [AuditorStatus.UNAPPROVED, AuditorStatus.ON_HOLD, AuditorStatus.ON_HOLD_NO_WORK].includes(
          data.auditor_status,
        ),
      },
      {
        title: 'Inactivation date',
        testTitle: `${dataCy}-inactivation-date`,
        value: data.inactivation_date ? getFormattedDate(data.inactivation_date) : '-',
      },
      {
        title: 'NARV audit count',
        testTitle: `${dataCy}-narv-count`,
        value: data.auditor_narv_visit_count || '-',
      },
      {
        title: 'Auditor pass rate',
        testTitle: `${dataCy}-pass-rate`,
        value: data.auditor_pass_rate ? `${data.auditor_pass_rate}%` : '-',
      },
      {
        title: 'First NARV audit',
        testTitle: `${dataCy}-first-narv-date`,
        value: data.auditor_first_narv_visit_date ? getFormattedDate(data.auditor_first_narv_visit_date) : '-',
      },
      {
        title: 'Last NARV audit',
        testTitle: `${dataCy}-last-narv-date`,
        value: data.auditor_last_narv_visit_date ? getFormattedDate(data.auditor_last_narv_visit_date) : '-',
      },
      {
        title: 'SL audit count',
        testTitle: `${dataCy}-count`,
        value: data.auditor_sl_visit_count || '-',
      },
      {
        title: 'First SL audit',
        testTitle: `${dataCy}-first-audit`,
        value: data.auditor_first_sl_visit_date ? getFormattedDate(data.auditor_first_sl_visit_date) : '-',
      },
      {
        title: 'Last SL audit',
        testTitle: `${dataCy}-last-audit`,
        value: data.auditor_last_sl_visit_date ? getFormattedDate(data.auditor_last_sl_visit_date) : '-',
      },
    ],
    [data, formattedTransportAvailable],
  );

  const AUDITOR_RIGHTS = useMemo(
    () => [
      {
        title: 'Right to work checked?',
        testName: `${dataCy}-right-to-work`,
        formName: 'right_to_work',
        updatedBy: data.right_to_work_updated_by
          ? `${data.right_to_work_updated_by_name} - ${getFormattedDate(data.right_to_work_updated_at)}`
          : '',
      },
      {
        title: 'Briefed for SSL?',
        testName: `${dataCy}-briefed-for-ssl`,
        formName: 'briefed_for_ssl',
        updatedBy: data.briefed_for_ssl_updated_by
          ? `${data.briefed_for_ssl_updated_by_name} - ${getFormattedDate(data.briefed_for_ssl_updated_at)}`
          : '',
      },
      {
        title: 'Briefed for NARV?',
        testName: `${dataCy}-briefed-for-narv`,
        formName: 'briefed_for_narv',
        updatedBy: data.briefed_for_narv_updated_by
          ? `${data.briefed_for_narv_updated_by_name} - ${getFormattedDate(data.briefed_for_narv_updated_at)}`
          : '',
      },
    ],
    [data],
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.basicInfo}>
          <Image
            rounded
            className={styles.image}
            size="small"
            src={data.image}
            onClick={() => setModalImgSrc(data.image)}
          />
          <div className={styles.auditor}>
            <div className={styles.namePhoneEmailContainer}>
              <div className={styles.preferedName} data-cy={`${dataCy}-preferred-name`}>
                {data.preferred_first_name
                  ? [data.preferred_first_name, data.preferred_last_name].join(' ')
                  : [data.first_name, data.last_name].join(' ')}
              </div>
              <a href={generateWhatsAppLink(data.mobile_prefix, data.mobile_number)} rel="noreferrer" target="_blank">
                <Label
                  className={styles.label}
                  content={getPhoneNumberWithDialCode(data.mobile_prefix, data.mobile_number)}
                  data-cy={`${dataCy}-mobile-number`}
                  icon="phone"
                />
              </a>
              <Label
                as="a"
                className={styles.label}
                content={data.email}
                data-cy={`${dataCy}-email`}
                href={`mailto:${data.email}`}
                icon="mail"
              />
            </div>
            <div className={styles.auditorInfo}>
              {AUDITOR_INFO.map(
                ({ title, testTitle, value, condition }) => (condition === undefined || condition) && (
                  <div key={title} className={styles.statistic}>
                    <span className={styles.statisticTitle}>
                      {title}
                    </span>
                    <span className={styles.statisticValue} data-cy={testTitle}>
                      {value}
                    </span>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
        <div className={styles.notesRights}>
          <div className={styles.flexContainer}>
            <Formik initialValues={data} onSubmit={values => onNotesChange(values)}>
              {({ handleSubmit }) => (
                <>
                  <span className={styles.notesRightsTitle}>
                    {'Notes'}
                  </span>
                  <FormFieldWrapper
                    className={styles.notesfield}
                    data-cy={`${dataCy}-notes`}
                    name="notes"
                    type="textarea"
                  />
                  <Button
                    primary
                    className={styles.notesButton}
                    data-cy={`${dataCy}-notes-save`}
                    size="tiny"
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save Notes
                  </Button>
                </>
              )}
            </Formik>
          </div>
          <div className={styles.flexContainer}>
            <Formik initialValues={data} onSubmit={values => onRightsChange(values)}>
              {({ dirty }) => (
                <>
                  <AutoSubmit />
                  <span className={styles.notesRightsTitle}>
                    {'Actions'}
                  </span>
                  <div className={styles.rightsContainer}>
                    {AUDITOR_RIGHTS.map(({ title, testName, formName, updatedBy }) => (
                      <div key={title} className={styles.rights}>
                        <div className={styles.rightsTitle} data-cy={testName}>
                          {title}
                        </div>
                        <div className={styles.rightsCheckboxContainer}>
                          <FormFieldCheckboxWrapper
                            data-cy={`${testName}-checkbox`}
                            label=" "
                            labelAfterCheckbox={false}
                            name={formName}
                          />
                          <div data-cy={`${testName}-details`}>
                            {updatedBy}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.rightsloader}>
                    {dirty && <WaitPlaceholder />}
                  </div>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {modalImgSrc && (
        <FullScreenImageModalV2
          data={data}
          entityId={data.id}
          imgSrc={modalImgSrc}
          name={'image'}
          refetch={refetch}
          onClose={() => setModalImgSrc(null)}
        />
      )}
    </>
  );
};

AuditorDetailsProfile.propTypes = {
  data: PropTypes.object.isRequired,
  dataCy: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AuditorDetailsProfile;
