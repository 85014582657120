/* eslint-disable import/prefer-default-export */
import { accountAPI } from '../api';
import AuditorDetailsEdit from '../components/pages/Auditors/AuditorDetailsEdit/AuditorDetailsEdit.component';
import UserDetails from '../components/pages/Users/UserDetails/UserDetails.component';
import { UserRole } from '../constants';

export const getAccountRoleProps = role => {
  if (!role) return null;

  if (role === UserRole.AUDITOR) {
    return {
      query: accountAPI.fetchAuditorAccount,
      body: AuditorDetailsEdit,
    };
  }

  return {
    query: accountAPI.fetchUserAccount,
    body: UserDetails,
  };
};
