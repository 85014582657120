/* eslint-disable func-names */
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import styles from './RequestPasswordForm.module.scss';

const DATA_CY = 'request-password-form';

const defaultValues = {
  email: '',
  code: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

const RequestPasswordSchemaStep1 = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
});

const RequestPasswordSchemaStep2 = Yup.object().shape({
  code: Yup.string()
    .required('Please enter the code received in your email'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  newPassword: Yup.string()
    .test('pwd-is-not-username', 'Password cannot be the same as username', function (value) {
      const { email } = this.parent;
      return value !== email;
    })
    .matches(/^(?=^.{12,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
      message: 'Incorrect format - min 12 characters, 1 uppercase, 1 lowercase and 1 symbol',
      excludeEmptyString: false,
    })
    .required('Required'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Required'),
});

const RequestPasswordForm = ({ requestPassword }) => {
  const [step, setStep] = React.useState(1);

  return (
    <>
      {step === 1
      && <Formik
        initialValues={defaultValues}
        validationSchema={RequestPasswordSchemaStep1}
        onSubmit={async (email, formikBag) => {
          try {
            await requestPassword(email);
            setStep(2);
          } catch (error) {
            // Alredy called on wrapper Form:
            // alert.error(`Error requesting new password: ${error.message}`);
          } finally {
            formikBag.resetForm(defaultValues);
          }
        // eslint-disable-next-line react/jsx-closing-bracket-location
        }}>
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
            <Form.Input
              className={styles.emailInput}
              data-cy={`${DATA_CY}-email-input`}
              error={errors.email && touched.email ? errors.email : null}
              id="email"
              name="email"
              placeholder="Email Address"
              type="email"
              value={values.email}
              onChange={handleChange}
            />
            <Button
              primary
              data-cy={`${DATA_CY}-submit-button`}
              size="big"
              type="submit"
            >
              Get code for password recovery
            </Button>
          </Form>
        )}
      </Formik>
      }
      {step === 2
      && <Formik
        initialValues={defaultValues}
        validationSchema={RequestPasswordSchemaStep2}
        onSubmit={async (authValues, formikBag) => {
          await requestPassword(authValues);
          formikBag.resetForm(defaultValues);
        // eslint-disable-next-line react/jsx-closing-bracket-location
        }}>
        {({ values, handleChange, errors, touched, handleSubmit }) => (
          <Form noValidate className={styles.root} data-cy={DATA_CY} onSubmit={handleSubmit}>
            <Form.Input
              className={styles.emailInput}
              data-cy={`${DATA_CY}-code-input`}
              error={errors.code && touched.code ? errors.code : null}
              id="code"
              name="code"
              placeholder="Code received"
              type="password"
              value={values.code}
              onChange={handleChange}
            />
            <Form.Input
              className={styles.emailInput}
              data-cy={`${DATA_CY}-email-input`}
              error={errors.email && touched.email ? errors.email : null}
              id="email"
              name="email"
              placeholder="Email Address"
              type="email"
              value={values.email}
              onChange={handleChange}
            />
            <Form.Input
              className={styles.emailInput}
              data-cy={`${DATA_CY}-newPassword-input`}
              error={errors.newPassword && touched.newPassword
                ? errors.newPassword : null}
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              type="password"
              value={values.newPassword}
              onChange={handleChange}
            />
            <Form.Input
              className={styles.emailInput}
              data-cy={`${DATA_CY}-newPassword_confirm-input`}
              error={errors.newPasswordConfirmation
                && touched.newPasswordConfirmation
                ? errors.newPasswordConfirmation : null}
              id="newPassword_confirm"
              name="newPasswordConfirmation"
              placeholder="Confirm new password"
              type="password"
              value={values.newPasswordConfirmation}
              onChange={handleChange}
            />
            <Button
              primary
              data-cy={`${DATA_CY}-submit-button`}
              size="big"
              type="submit"
            >
              Email New Password
            </Button>
          </Form>
        )}
      </Formik>
      }
    </>
  );
};

RequestPasswordForm.propTypes = {
  requestPassword: PropTypes.func.isRequired,
};

export default RequestPasswordForm;
