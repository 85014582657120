import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Label } from 'semantic-ui-react';

import { auditorsAPI, auditsAPI } from '../../../../api';
import { AuditStatus } from '../../../../constants';
import { FieldStatus, getAuditorActionStatus } from '../../../../fieldLogic';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import { SLHeader } from '../../../common';
import GenericModal from '../../../common/GernericModal/GenericModal.component';
import { formatStatus, formatStatusLabel } from '../helpers';
import styles from './AuditorDetailsHeader.module.scss';

const DATA_CY = 'auditor-details-header';

const auditorStatusOptionsSelector = state => enumOptionsSelector(state, 'auditor_status_type');

const AuditorDetailsHeader = ({ data, refetch, onCancel, onEdit }) => {
  const { entityId } = useParams();
  const alert = useAlert();
  const role = useUserRole();
  const [assignedAuditsModal, setAssignedAuditsModal] = useState(false);
  const [assignedAudits, setAssignedAudits] = useState([]);
  let statusToSet;

  const auditorStatusOptions = useSelector(auditorStatusOptionsSelector);

  const { mutate: updateAuditorStatus } = useMutation(auditorsAPI.updateStatus, {
    onSuccess: () => {
      alert.success('Auditor status updated');
      refetch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error updating auditor's state: ${error.message}`);
    },
  });

  // do the same on header for v1
  const { mutate: fetchAssignedByAuditorId } = useMutation(auditsAPI.fetchAssignedByAuditorId, {
    onSuccess: audits => {
      if (audits.length > 0 && audits.some(audit => audit.status === AuditStatus.ASSIGNED)) {
        setAssignedAudits(audits);
        setAssignedAuditsModal(true);
      } else {
        updateAuditorStatus({
          entityId,
          status: statusToSet,
        });
        refetch();
      }
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error fetching auditors assigned audits: ${error.message}`);
    },
  });

  const updateStatus = async status => {
    statusToSet = status;
    if (statusToSet === 'inactive') {
      fetchAssignedByAuditorId({ auditorId: data.id });
    } else {
      updateAuditorStatus({
        entityId,
        status: statusToSet,
      });
      refetch();
    }
  };

  return (
    <SLHeader
      className={styles.header}
      dataCy={DATA_CY}
      status={
        (<div data-cy={`${DATA_CY}-status`}>
          <Label color="violet">
            {formatStatusLabel(data.auditor_status)}
          </Label>
        </div>)
      }
      title={`${data?.internal_id || 'Auditor'} profile`}
    >

      {auditorStatusOptions.map(status => (
        getAuditorActionStatus(role, data?.auditor_status, `action_${status.value}`) !== FieldStatus.HIDDEN ? (
          <Button
            key={status.value}
            secondary
            onClick={() => updateStatus(status.value)}
          >
            {formatStatus(status.text)}
          </Button>
        ) : null))}

      <Button
        className={styles.edit}
        data-cy={`${DATA_CY}-edit-button`}
        onClick={onEdit}
      >
        Edit
      </Button>
      <Button
        data-cy={`${DATA_CY}-back-button`}
        onClick={onCancel}
      >
        Back
      </Button>
      {
        assignedAuditsModal && (
          <GenericModal
            buttons={[
              { label: 'Close', func: () => setAssignedAuditsModal(false), isPrimary: true },
            ]}
            headerText="Audits are assigned to this auditor"
            open={assignedAuditsModal}
          >
            <div className={styles.modal}>
              This auditor currently has audits assigned. Please check what they are and
              speak to the relevant team before inactivating. If these are initial or error
              assessments, please unassign before inactivating.
              <div className={styles.scrollBox}>
                {assignedAudits.filter(audit => audit.status === AuditStatus.ASSIGNED).map(audit => (
                  <div key={audit.internal_id}>
                    -
                    {' '}
                    {audit.internal_id}
                  </div>
                ))}
              </div>

            </div>
          </GenericModal>
        )
      }
    </SLHeader>
  );
};

AuditorDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default AuditorDetailsHeader;
