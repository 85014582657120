import React from 'react';
import { useSelector } from 'react-redux';

import { taxonomyTermSelector } from '../../../../../state/constants/selectors';
import { FormFieldSelectWrapper, FormFieldWrapper, TaxonomyTermOptions } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import styles from './RegisterAuditorFormEleven.module.scss';

const hearOptionsSelector = state => taxonomyTermSelector(state, 'where_did_you_here_about_us');

const RegisterFormAuditorFormEleven = () => {
  const hearOptions = useSelector(hearOptionsSelector);

  return (
    <FormLayout subtitle="1 of 2" title="Tell us">
      <span className={styles.titleQuestion}>
        {'Where did you hear about us?'}
      </span>
      <FormFieldSelectWrapper
        required
        name="hear_about_us"
        options={TaxonomyTermOptions(hearOptions)}
        placeholder="Select an option"
      />
      <span className={styles.titleQuestion}>
        {'Please give details'}
      </span>
      <FormFieldWrapper
        required
        name="notes"
        placeholder="Type your answer here..."
        rows={3}
        type="textarea"
      />
      <p className={styles.titleQuestion}>
        <strong>
          {'Did someone refer you? '}
        </strong>
        {'If so, please enter their referral code below'}
      </p>
      <FormFieldWrapper
        required
        name="recommended_by"
        placeholder="Referral code"
      />
    </FormLayout>
  );
};

export default RegisterFormAuditorFormEleven;
