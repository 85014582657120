import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const extractCodeAndDialCode = prefix => {
  const [code, dialCode] = (prefix || '').split(/\+(\d+)/);
  return { code, dialCode };
};

export const getPhoneNumberWithDialCode = (prefix, number) => {
  const extractedPrefix = extractCodeAndDialCode(prefix);
  return `${extractedPrefix.dialCode ? `+${extractedPrefix.dialCode} ` : ''}${number}`;
};

export const generateWhatsAppLink = (prefix, number) => {
  const countryCode = prefix ? prefix.split('+')[1] : '';
  const cleanNumber = number.replace(/\D/g, '');
  const formattedNumber = countryCode + cleanNumber;

  return `https://wa.me/${formattedNumber}`;
};

export const formatPhoneNumber = (prefix, number, national = true) => {
  if (!prefix || !number) return number;

  const { dialCode } = extractCodeAndDialCode(prefix);
  if (!dialCode) return number;

  try {
    const phoneNumber = parsePhoneNumberFromString(`+${dialCode}${number.replace(/\D/g, '')}`);
    if (phoneNumber) {
      if (national) {
        return phoneNumber.formatNational();
      }
      return phoneNumber.formatInternational();
    }
    return number;
  } catch (error) {
    return number;
  }
};
