import { useFormikContext } from 'formik';
import React from 'react';
import { Form } from 'semantic-ui-react';

import { DateTimeFormat } from '../../../../../constants';
import { FormFieldCheckboxWrapper, FormFieldDateTimeWrapper, FormFieldImageUploadWrapper, FormFieldSelectWrapper, FormFieldWrapper } from '../../../../common';
import FormLayout from '../../RegisterAuditorFormLayout/RegisterAuditorFormLayout.component';
import { hasVisaExpirationDate } from '../../RegisterAuditorFormWrapper/helpers';
import styles from './RegisterAuditorFormStepSevenExtra.module.scss';

const RegisterAuditorFormStepSevenExtra = () => {
  const { values } = useFormikContext();

  return (
    <FormLayout subtitle="extra" title="Upload images">
      <span className={styles.titleQuestion}>
        {'Right to Work checks'}
      </span>
      <p className={styles.titleQuestion}>
        {'To find your share code, please visit and follow the instructions:'}
        <br />
        {'https://www.gov.uk/view-prove-immigration-status'}
      </p>
      <p className={styles.titleQuestion}>
        {'You will be asked for your employer’s email address, please enter the following:'}
        <br />
        {'application-support@servelegal.co.uk'}
      </p>
      <Form.Field className={styles.formFieldBlock} width={16}>
        <FormFieldCheckboxWrapper
          labelAfterCheckbox
          label="I do not require a share code"
          name="share_code_not_required"
        />
        {!values.share_code_not_required
        && <>
          <FormFieldWrapper
            name={'share_code'}
            placeholder={'Enter your share code'}
          />
        </>
        }
      </Form.Field>
      <Form.Field className={styles.formFieldBlock} width={16}>
        <FormFieldCheckboxWrapper
          labelAfterCheckbox
          label="I do not require further evidence of right to work"
          name={'evidence_not_required'}
        />
      </Form.Field>
      {!values.evidence_not_required
      && <>
        <span className={styles.titleQuestion}>
          {'Please attach a scanned copy of your Settlement/Pre-Settlement/Agreement/Biometric residence permit/VISA'}
        </span>
        <Form.Field className={styles.formFieldBlock} width={16}>
          <FormFieldImageUploadWrapper
            thumbnail
            accept={['image/jpg', 'image/png']}
            name="visa"
          />
        </Form.Field>
        <span className={styles.noteQuestion}>
          {'Allowed file types: .jpg, .png'}
        </span>
        <span className={styles.titleQuestion}>
          {'Does your evidence of right to work have an expired date?'}
        </span>
        <FormFieldSelectWrapper
          required
          name="visa_expiration_date_required"
          options={hasVisaExpirationDate}
          placeholder="Select an option"
        />
        {values.visa_expiration_date_required && <>
          <span className={styles.titleQuestion}>
            {'Specify the expiration date of your visa'}
          </span>
          <Form.Field width={16}>
            <FormFieldDateTimeWrapper
              format={DateTimeFormat.DAY_MONTH_YEAR}
              name="visa_expiration"
              time={false}
            />
          </Form.Field>
        </>}
      </> }

    </FormLayout>
  );
};
export default RegisterAuditorFormStepSevenExtra;
