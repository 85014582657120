import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Dimmer, Divider, Form, List, Loader } from 'semantic-ui-react';

import { entitiesAPI, errorAPI, multipartAPI, surveyRevisionsAPI, taxonomyAPI } from '../../../../api';
import { DateTimeFormat, Entity, UserRole } from '../../../../constants';
import FieldStatus from '../../../../fieldLogic/fieldStatus';
import getOrdersFieldStatus from '../../../../fieldLogic/orders';
import history from '../../../../history';
import { useUserRole } from '../../../../hooks';
import { enumOptionsSelector, taxonomyTermSelector } from '../../../../state/constants/selectors';
import buildDocumentsTree from '../../../../utils/buildDocumentsTree';
import downloadFile from '../../../../utils/downloadFile';
import {
  FormFieldCheckboxWrapper,
  FormFieldCurrencyWrapper,
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldFileWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  RichEditor,
  TaxonomyTermOptions,
} from '../../../common';
import UploadResult from '../../../common/UploadResult/UploadResult.component';
import { entityType, uuidType } from '../../../types';
import { orderDefaultValues, orderValidationSchema } from '../helpers';
import OrderActions from './OrderActions/OrderActions.component';
import OrderBriefingDocs from './OrderBriefingDocs/OrderBriefingDocs.component';
import styles from './OrderDetails.module.scss';
import OrderDetailsCopy from './OrderDetailsCopy/OrderDetailsCopy.component';
import OrderDetailsTokens from './OrderDetailsTokens/OrderDetailsTokens.component';
import OrderFailLetterPDF from './OrderFailLetterPDF/OrderFailLetterPDF.component';

const DATA_CY = 'order-details';

const scheduleTypeOptionsSelector = state => enumOptionsSelector(state, 'schedule_type');
const orderStatusTypeOptionsSelector = state => enumOptionsSelector(state, 'order_status_type');
const currencyTypeOptionsSelector = state => enumOptionsSelector(state, 'currency_type');
const itemToOrderTaxonomyTermSelector = state => taxonomyTermSelector(state, 'order_item');

const OrderDetails = ({
  data,
  entity,
  entityId,
  revisionId,
  submitFormRef,
}) => {
  const alert = useAlert();
  const role = useUserRole();

  const scheduleTypeOptions = useSelector(scheduleTypeOptionsSelector);
  const orderStatusTypeOptions = useSelector(orderStatusTypeOptionsSelector);
  const currencyTypeOptions = useSelector(currencyTypeOptionsSelector);
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomyTermSelector);

  const [auditsUploadStatus, setAuditsUploadStatus] = useState(null);
  const [isBriefingDocsModalOpen, setBriefingDocsModalOpen] = useState(false);

  const downloadTemplate = () => {
    entitiesAPI.fetchTemplate({ entity: Entity.AUDITS, template: 'create' })
      .then(template => downloadFile(template, `${Entity.AUDITS}_create_template.csv`))
      .catch(error => errorAPI.sendError(error.message, ''));
  };

  const buildClientSearchOptions = clients => clients.map(client => ({
    text: client.name,
    value: client.id,
  }));

  const [filteredClients, setFilteredClients] = useState(entityId
    ? [{
      text: data.client_name,
      value: data.client_id,
    }]
    : []);

  const searchClients = wildcard => {
    entitiesAPI.fetchAll(Entity.CLIENTS, { name: wildcard })
      .then(clients => setFilteredClients(buildClientSearchOptions(clients.items)))
      .catch(error => {
        alert.error(`Error fetching clients: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const buildSurveySearchOptions = surveys => surveys.map(survey => ({
    text: survey.title,
    value: survey.id,
  }));

  const [filteredSurveys, setFilteredSurveys] = useState(entityId
    ? [{
      text: data.survey_title,
      value: data.survey_id,
    }]
    : []);

  const searchSurveys = wildcard => {
    entitiesAPI.fetchAll(Entity.SURVEYS, { title: wildcard })
      .then(surveys => setFilteredSurveys(buildSurveySearchOptions(surveys.items)))
      .catch(error => {
        alert.error(`Error fetching surveys: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const buildSurveyRevisionSearchOptions = revisions => revisions.map(revision => ({
    text: revision.name,
    value: revision.id,
  }));

  const [filteredSurveyRevisions, setFilteredSurveyRevisions] = useState(entityId
    ? [{
      text: data.revision_name,
      value: data.revision_id,
    }]
    : []);

  const searchSurveyRevisions = (wildcard, surveyId) => {
    surveyRevisionsAPI.fetchAll(surveyId, { name: wildcard, status: 'active' })
      .then(revisions => setFilteredSurveyRevisions(buildSurveyRevisionSearchOptions(revisions.items)))
      .catch(error => {
        alert.error(`Error fetching revisions: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const [briefingDocumentsTree, setBriefingDocumentsTree] = useState([]);
  const { data: briefingDocuments } = useQuery(
    'briefing_documents',
    () => taxonomyAPI.fetchAllDocuments(),
  );

  useEffect(() => {
    if (briefingDocuments?.items) {
      setBriefingDocumentsTree(buildDocumentsTree({
        docs: briefingDocuments.items,
      }));
    }
  }, [briefingDocuments]);

  const {
    mutate: orderMutate,
    isLoading: isOrderMutateLoading,
  } = useMutation(entityId ? multipartAPI.update : multipartAPI.create, {
    onSuccess: response => {
      if (response.uploadedAudits?.failed?.length > 0) {
        alert.info('Order saved but there were errors uploading some audits');
        setAuditsUploadStatus(response.uploadedAudits);
      } else {
        alert.success(`Order ${entityId ? 'updated' : 'created'}`);
        history.push('/orders');
      }
    },
    onError: error => {
      alert.error(`Error ${entityId ? 'updating' : 'creating'} order: ${error.message}`);
      errorAPI.sendError(error.message, error.stack);
    },
  });

  const onSubmit = values => {
    const formData = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (typeof value === 'object' && key !== 'file') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    orderMutate({
      entity,
      entityId,
      payload: formData,
    });
  };

  const briefingDocumentsFieldStatus = getOrdersFieldStatus(role, entityId === undefined, revisionId)('briefing_documents');

  return isOrderMutateLoading ? (
    <>
      <Dimmer active inverted>
        <Loader size="large">
          {'Uploading order'}
        </Loader>
      </Dimmer>
    </>
  ) : (
    <>
      {revisionId === null && (
        <div className={styles.actionsRow}>
          <OrderActions entityId={entityId} />
        </div>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={data || orderDefaultValues}
        validationSchema={orderValidationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form data-cy={`${DATA_CY}-form`}>
            {submitFormRef ? <button ref={submitFormRef} hidden type="submit" onClick={handleSubmit} /> : null}

            <FormFieldDynamicSearchWrapper
              inline
              required
              label="Client Name"
              name="client_id"
              options={filteredClients}
              onSearchChange={searchClients}
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldSelectWrapper
              inline
              required
              label="Schedule Type"
              name="schedule_type"
              options={scheduleTypeOptions}
              placeholder="Select a value"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldSelectWrapper
              inline
              required
              label="Item to Order"
              name="item_to_order"
              options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)
                .sort((a, b) => a.text.localeCompare(b.text))
              }
              placeholder="Select a value"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Order Info"
              name="order_info"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Audits to be billed"
              name="visits_to_be_billed"
              type="number"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              readOnly
              label="Audits in system"
              name="audits_in_system"
              type="number"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldDynamicSearchWrapper
              inline
              required
              label="Survey"
              name="survey_id"
              options={filteredSurveys}
              onChange={() => setFieldValue('revision_id', '')}
              onSearchChange={searchSurveys}
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldDynamicSearchWrapper
              inline
              required
              disabled={values?.survey_id?.length === 0}
              label="Revision"
              name="revision_id"
              options={filteredSurveyRevisions}
              onSearchChange={searchQuery => searchSurveyRevisions(searchQuery, values?.survey_id)}
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            {!revisionId ? (
              <>
                <Divider />

                <FormFieldFileWrapper
                  inline
                  accept=".csv"
                  help={
                    <div className={styles.siteUploadHelp}>
                      {'Create audits for this order by downloading the '}
                      <span
                        className={styles.example}
                        data-cy={`${DATA_CY}-example`}
                        onClick={downloadTemplate}
                      >
                        {'example sites CSV file'}
                      </span>
                      {' and uploading the new above.'}
                    </div>
                  }
                  label="Upload Sites"
                  name="file"
                  onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                />
              </>
            ) : null}

            {auditsUploadStatus ? (
              <Form.Group>
                <Form.Field width={3} />
                <Form.Field width={9}>
                  <UploadResult data={auditsUploadStatus} />
                </Form.Field>
              </Form.Group>
            ) : null}

            <Divider />

            <FormFieldDateTimeWrapper
              inline
              required
              format={DateTimeFormat.DAY_MONTH_YEAR}
              label="Order date start"
              name="start_date"
              time={false}
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldDateTimeWrapper
              inline
              required
              format={DateTimeFormat.DAY_MONTH_YEAR}
              label="Order date end"
              name="end_date"
              time={false}
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <Divider />

            <FormFieldSelectWrapper
              inline
              required
              label="Currency"
              name="currency"
              options={currencyTypeOptions}
              placeholder="Select a value"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldCurrencyWrapper
              inline
              currency={values?.currency}
              label="Auditor Pay per Audit"
              name="auditor_pay_per_audit"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldCurrencyWrapper
              inline
              currency={values?.currency}
              label="Client Charge per Audit"
              name="client_charge_per_audit"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Auditor Instructions"
              name="auditor_instructions"
              type="textarea"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldWrapper
              inline
              label="Approving notes"
              name="approving_notes"
              type="textarea"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            {briefingDocumentsFieldStatus !== FieldStatus.HIDDEN ? (
              <Form.Group>
                <Form.Field width={3}>
                  <label className={styles.label}>
                    {'Briefing Documents'}
                  </label>
                </Form.Field>
                <Form.Field width={12}>
                  <List>
                    {(values?.briefing_documents || []).map(value => {
                      const found = briefingDocuments?.items.find(d => d.id === value);

                      return (found?.value ? (
                        <List.Item key={value}>
                          <List.Icon name="file" />
                          <List.Content>
                            {found.value}
                          </List.Content>
                        </List.Item>
                      ) : (
                        <List.Item key={value}>
                          <List.Icon name="warning" />
                          <List.Content>
                            {`'${value}' not found`}
                          </List.Content>
                        </List.Item>
                      ));
                    })}
                  </List>

                  {briefingDocumentsFieldStatus === FieldStatus.EDITABLE ? (
                    <>
                      <Button onClick={() => setBriefingDocsModalOpen(true)}>
                        {'Edit'}
                      </Button>

                      <OrderBriefingDocs
                        docs={briefingDocumentsTree}
                        open={isBriefingDocsModalOpen}
                        selectedDocs={values.briefing_documents}
                        onCancel={() => setBriefingDocsModalOpen(false)}
                        onConfirm={newBriefingDocs => {
                          setFieldValue('briefing_documents', newBriefingDocs);
                          setBriefingDocsModalOpen(false);
                        }}
                      />
                    </>) : null}
                </Form.Field>
              </Form.Group>
            ) : null}

            <Divider />

            <Form.Group>
              <Form.Field width={12}>
                <Form.Group>
                  <Form.Field width={1} />
                  <Form.Field width={3}>
                    <label className={styles.label}>
                      {'Email Subject'}
                    </label>
                  </Form.Field>
                  <Form.Field width={12}>
                    <FormFieldWrapper
                      label=""
                      name="email_subject"
                      onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field width={1} />
                  <Form.Field width={3}>
                    <label className={styles.label}>
                      {'Email Survey Result'}
                    </label>
                  </Form.Field>
                  <Form.Field width={12}>
                    <FormFieldWrapper
                      label=""
                      name="email_survey_result"
                      onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field width={1} />
                  <Form.Field width={3} />
                  <Form.Field width={12}>
                    <OrderDetailsCopy
                      entityId={entityId}
                      name="email_copy"
                      revisionId={revisionId}
                      role={role}
                    />
                  </Form.Field>
                </Form.Group>
              </Form.Field>

              {!revisionId ? (
                <Form.Field width={4}>
                  <OrderDetailsTokens />
                </Form.Field>
              ) : null}
            </Form.Group>

            <Divider />

            <>
              <FormFieldCheckboxWrapper
                inline
                toggle
                defaultProps={false}
                help="Check this if the auditor should be able to send automatic fail letter notifications"
                label="Automatic Fail Letter Notification"
                name="automatic_fail_letter_notification"
                onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
              />
              {values.automatic_fail_letter_notification && (
                <>
                  <FormFieldWrapper
                    inline
                    label="Email Subject"
                    name="automatic_fail_letter_notification_email_subject"
                    onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                  />

                  <Form.Group>
                    {[FieldStatus.EDITABLE, FieldStatus.READONLY]
                      .includes(getOrdersFieldStatus(role, entityId === undefined, revisionId)('automatic_fail_letter_notification_email_body')) && (
                      <>
                        <Form.Field width={3}>
                          <label className={styles.label}>
                            {'Email Body'}
                          </label>
                        </Form.Field>
                      </>
                    )}

                    <Form.Field width={9}>
                      <RichEditor
                        editorStyle={{ backgroundColor: 'white', borderRadius: '5px', borderColor: '#5F8298' }}
                        name="automatic_fail_letter_notification_email_body"
                        readOnly={UserRole.CLIENT_SERVICES === role}
                        toolbarStyle= {{ borderRadius: '5px' }}
                        onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
                      />
                    </Form.Field>
                  </Form.Group>
                  <OrderFailLetterPDF
                    documents={briefingDocuments?.items || []}
                    isNew={entityId === undefined}
                    name={'automatic_fail_letter_notification_pdf'}
                    revisionId={revisionId}
                    role={role}
                  />
                </>
              )}
              <Divider />
            </>

            <FormFieldCheckboxWrapper
              inline
              toggle
              defaultProps={false}
              help="Check this if the audit should be automatically approved when all matching rules pass"
              label="Automatic Approval"
              name="automatic_approval"
            />

            <FormFieldCheckboxWrapper
              inline
              toggle
              label="Exclude from Client Reporting"
              name="exclude_from_client_reporting"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldCheckboxWrapper
              inline
              toggle
              help="Check this if the audits should not be used to calculate pass rates, and should be excluded from SL audits counts."
              label="NARV audits only"
              name="narv_visits_only"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

            <FormFieldSelectWrapper
              inline
              required
              label="Status"
              name="status"
              options={orderStatusTypeOptions}
              placeholder="Select a value"
              onStateHandler={getOrdersFieldStatus(role, entityId === undefined, revisionId)}
            />

          </Form>
        )}
      </Formik>
    </>
  );
};

OrderDetails.defaultProps = {
  data: null,
  revisionId: null,
  submitFormRef: null,
};

OrderDetails.propTypes = {
  entity: entityType.isRequired,
  entityId: uuidType.isRequired,
  data: PropTypes.object, // TODO: orderType,
  revisionId: uuidType,
  submitFormRef: PropTypes.object,
};

export default OrderDetails;
