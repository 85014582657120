import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form, Grid, Icon, Modal } from 'semantic-ui-react';

import { entitiesAPI, errorAPI } from '../../../../api';
import { Entity, EntityState } from '../../../../constants';
import { taxonomyTermSelector } from '../../../../state/constants/selectors';
import {
  FormFieldDateTimeWrapper,
  FormFieldDynamicSearchWrapper,
  FormFieldSelectWrapper,
  FormFieldWrapper,
  TaxonomyTermOptions,
} from '../../../common';
import AuditorTooltip from '../../Auditors/AuditorTooltip/AuditorTooltip.component';
import DirectionsMap from '../../Map/DirectionsMap/DirectionsMap.component';

const itemToOrderTaxonomySelector = state => taxonomyTermSelector(state, 'order_item');

const AuditsTableBulkOperationsModal = ({ open, visibleFields, onCancel, onConfirm, selectedAudits }) => {
  const alert = useAlert();
  const [idAuditor, setIdAuditor] = useState(null);
  const itemToOrderTaxonomyTerm = useSelector(itemToOrderTaxonomySelector);

  const buildAuditorSearchOptions = auditors => auditors.map(auditor => ({
    description: (
      <AuditorTooltip
        id={auditor.id}
        position={'bottom center'}
        trigger={<Icon circular name="info" size="small" />}
      />
    ),
    text: auditor.name,
    value: auditor.id,
  }));

  const [filteredAuditors, setFilteredAuditors] = useState([]);

  const searchAuditors = wildcard => {
    entitiesAPI.fetchAll(Entity.AUDITORS, { name: wildcard, auditor_status: 'active' })
      .then(auditors => setFilteredAuditors(buildAuditorSearchOptions(auditors.items)))
      .catch(error => {
        alert.error(`Error fetching active auditors: ${error.message}`);
        errorAPI.sendError(error.message, error.stack);
      });
  };

  const { data: auditorData } = useQuery(
    [idAuditor],
    () => entitiesAPI.fetchOne({ entity: Entity.AUDITORS, entityId: idAuditor }),
    { enabled: idAuditor !== null },
  );

  const showMap = visibleFields.includes('auditor_id');

  const shouldScroll = visibleFields[0] !== 'deadline_date';

  return (
    <Modal
      closeOnDimmerClick={true}
      open={open}
      size="fullscreen"
      onClose={onCancel}
    >
      <Modal.Header>
        {'Audit Bulk Operations'}
      </Modal.Header>
      <Formik
        initialValues={{}}
        onSubmit={onConfirm}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Content scrolling={shouldScroll}>
              <Grid columns={showMap ? 2 : 1} divided={showMap}>
                <Grid.Column>
                  <Form>
                    {visibleFields.includes('auditor_id') && (
                      <FormFieldDynamicSearchWrapper
                        label="Auditor"
                        name="auditor_id"
                        options={filteredAuditors}
                        onChange={e => setIdAuditor(e)}
                        onSearchChange={searchAuditors}
                      />
                    )}

                    {visibleFields.includes('visit_info') && (
                      <FormFieldWrapper
                        label="Audit info"
                        name="visit_info"
                        type="textarea"
                      />
                    )}

                    {visibleFields.includes('auditor_instructions') && (
                      <FormFieldWrapper
                        label="Auditor Instructions"
                        name="auditor_instructions"
                        type="textarea"
                      />
                    )}

                    {visibleFields.includes('item_to_order') && (
                      <FormFieldSelectWrapper
                        label="Item to Order"
                        name="item_to_order"
                        options={TaxonomyTermOptions(itemToOrderTaxonomyTerm)
                          .sort((a, b) => a.text.localeCompare(b.text))
                        }
                        placeholder="Select a value"
                      />
                    )}

                    {visibleFields.includes('deadline_date') && (
                      <FormFieldDateTimeWrapper
                        label="Deadline Date"
                        name="deadline_date"
                        time={false}
                      />
                    )}

                    {visibleFields.includes('auditor_pay_per_audit') && (
                      <FormFieldWrapper
                        label={'Pay & Purchase Allowance'}
                        name="auditor_pay_per_audit"
                        type="number"
                      />
                    )}
                  </Form>
                </Grid.Column>

                <Grid.Column>
                  {visibleFields.includes('auditor_id') && (
                    <DirectionsMap
                      data={selectedAudits}
                      type={EntityState.TABLE}
                      user={auditorData}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={onCancel}>
                {'Cancel'}
              </Button>
              <Button primary onClick={handleSubmit}>
                {'Apply'}
              </Button>
            </Modal.Actions>
          </>
        )}
      </Formik>
    </Modal>
  );
};

AuditsTableBulkOperationsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedAudits: PropTypes.arrayOf(PropTypes.object).isRequired,
  visibleFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AuditsTableBulkOperationsModal;
