import PropTypes from 'prop-types';
import React from 'react';

import styles from './Toggle.module.scss';

const Toggle = ({ checked, onChange, offLabel, label }) => (
  <div className={styles.root}>
    {offLabel && (
      <span className={styles.label} onClick={onChange}>
        {offLabel}
      </span>
    )}
    <div className="ui toggle checkbox">
      <input checked={checked} type="checkbox" onChange={onChange} />
      <label />
    </div>
    <span className={styles.label} onClick={onChange}>
      {label}
    </span>
  </div>
);

Toggle.defaultProps = {
  checked: false,
  label: '',
  offLabel: '',
  onChange: () => {},
};

Toggle.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  offLabel: PropTypes.string,
  onChange: PropTypes.func,
};

export default Toggle;
