import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { extractCodeAndDialCode } from './mobileHelpers';

const trimValidation = value => !value || value.trim() === value;

const shareCodeValidationWithoutSpaces = /^[A-Za-z0-9]{9}$/;

const validateMobileNumber = (value, context) => {
  const { mobile_prefix } = context.parent;

  const rawValue = context.parent.mobile_number_raw || value;
  if (!rawValue || !mobile_prefix) return false;

  const { dialCode } = extractCodeAndDialCode(mobile_prefix);
  if (!dialCode) return false;

  try {
    const phoneNumber = parsePhoneNumberFromString(`+${dialCode}${rawValue.replace(/\D/g, '')}`);
    return phoneNumber && phoneNumber.isValid();
  } catch (error) {
    return false;
  }
};

export { shareCodeValidationWithoutSpaces, trimValidation, validateMobileNumber };
