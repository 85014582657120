import React, { useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Form, List, Loader } from 'semantic-ui-react';

import { entitiesAPI, errorAPI, multipartAPI } from '../../../../../api';
import downloadFile from '../../../../../utils/downloadFile';
import { LoadingPlaceholder } from '../../../../common';
import UploadResult from '../../../../common/UploadResult/UploadResult.component';
import styles from './EntityUpload.module.scss';

const TEMPLATE = 'upload';

const EntityUpload = () => {
  const { entity } = useParams();
  const alert = useAlert();
  const uploadRef = useRef(null);
  const [fileInfo, setFileInfo] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);

  const DATA_CY = `${entity}-upload`;

  const downloadTemplate = () => {
    entitiesAPI.fetchTemplate({ entity, template: TEMPLATE })
      .then(data => downloadFile(data, `${entity}_${TEMPLATE}_template.csv`))
      .catch(error => {
        alert.error(`Error downloading ${entity} export template: ${error.message}`);
        errorAPI.sendError(error.message, '');
      });
  };

  const handleFileSelect = () => {
    if (fileInfo) {
      setFileInfo(null);
      setUploadResponse(null);
    } else {
      uploadRef.current.click();
    }
  };

  const handleFileChange = e => {
    if (e.target.files?.length > 0) {
      setFileInfo(e.target.files[0]);
    } else {
      setFileInfo(null);
    }
  };

  const {
    mutate: uploadMutate,
    status: uploadMutateStatus,
  } = useMutation(multipartAPI.upload, {
    onSuccess: response => setUploadResponse(response),
    onError: error => {
      alert.error(`Error uploading file: ${error.message}`);
    },
  });

  const handleSubmit = () => {
    setUploadResponse(null);

    if (fileInfo) {
      const formData = new FormData();
      formData.append('file', fileInfo);

      uploadMutate({
        entity,
        payload: formData,
      });
    } else {
      setUploadResponse(null);
    }
  };

  return (
    uploadMutateStatus === 'loading' ? (
      <>
        <Dimmer active inverted>
          <Loader size="large">
            {`Uploading ${entity}`}
          </Loader>
        </Dimmer>
        <LoadingPlaceholder />
      </>
    ) : (
      <div className={styles.root} data-cy={DATA_CY}>
        <div data-cy={`${DATA_CY}-instructions`}>
          <span>
            {`To create or update existing ${entity} please do the following:`}
          </span>
          <List ordered>
            <List.Item>
              {'Download the '}
              <span className={styles.example} onClick={downloadTemplate}>
                {'example CSV file'}
              </span>
            </List.Item>
            <List.Item>
              {'Enter the details and save as .CSV'}
            </List.Item>
            <List.Item>
              {'Upload the new CSV file below:'}
            </List.Item>
          </List>
        </div>
        <Form
          className={styles.form}
          encType="multipart/form-data"
        >
          <Form.Field width={8}>
            <Form.Input
              readOnly
              action={
                <>
                  <Button
                    data-cy={`${DATA_CY}-file-input-button`}
                    onClick={handleFileSelect}
                  >
                    {fileInfo ? 'Clear' : 'Select'}
                  </Button>
                  <input
                    ref={uploadRef}
                    hidden
                    accept=".csv"
                    style={{ display: 'hidden' }}
                    type="file"
                    onChange={handleFileChange}
                  />
                </>
              }
              data-cy={`${DATA_CY}-file-input`}
              label="CSV File"
              placeholder="Select file..."
              value={fileInfo ? fileInfo.name : ''}
            >
            </Form.Input>
          </Form.Field>
          {fileInfo ? (
            <Button type="submit" onClick={handleSubmit}>
              Upload
            </Button>
          ) : null}

          {uploadResponse ? <UploadResult data={uploadResponse} /> : null}

        </Form>
      </div>)
  );
};

export default EntityUpload;
