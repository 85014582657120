import { IconLayer } from '@deck.gl/layers';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Divider, Grid, Icon, Image } from 'semantic-ui-react';

import defaultIcon from '../../../assets/images/mapFeature/audit_icon_24.png';
import {
  BluePushpinMarker,
  GreenPushpinMarker,
  LightBlueMarker,
  PinkMarker,
  PurplePushpinMarker,
  RedMarker,
  RedPushpinMarker,
  YellowPushpinMarker,
} from '../../../assets/images/markers';
import { AuditStatus, CoordinatesTypes, Entity, EntityState, UserRole } from '../../../constants';
import { createLatLng, createMarker, itemCoordinates } from '../../../utils/googleMapsHelpers';
import styles from './Map.module.scss';

const auditIcon = {
  [AuditStatus.UNASSIGNED]: PurplePushpinMarker,
  [AuditStatus.ASSIGNED]: YellowPushpinMarker,
  [AuditStatus.APPROVED]: GreenPushpinMarker,
  default: BluePushpinMarker,
};

const getAuditIcon = status => auditIcon[status] || auditIcon.default;

const auditorHomeCoordinates = item => itemCoordinates({
  coords: item?.home_coordinates,
  title: CoordinatesTypes.HOME,
  icon: RedMarker,
  item,
});

const auditorTermCoordinates = item => itemCoordinates({
  coords: item?.term_coordinates,
  title: CoordinatesTypes.TERM,
  icon: LightBlueMarker,
  item,
});

const auditorCurrentCoordinates = item => itemCoordinates({
  coords: item?.current_coordinates,
  title: CoordinatesTypes.CURRENT,
  icon: PinkMarker,
  item,
});

const siteCoordinates = item => itemCoordinates({
  coords: item?.site_coordinates,
  title: CoordinatesTypes.SITE,
  icon: getAuditIcon(item?.status),
  item,
});

const auditStartCoordinates = item => itemCoordinates({
  coords: item?.audit_start_coordinates,
  title: CoordinatesTypes.AUDIT_START,
  icon: RedPushpinMarker,
  item,
});

const place = {
  [CoordinatesTypes.CURRENT]: auditorCurrentCoordinates,
  [CoordinatesTypes.HOME]: auditorHomeCoordinates,
  [CoordinatesTypes.TERM]: auditorTermCoordinates,
  [CoordinatesTypes.SITE]: siteCoordinates,
  [CoordinatesTypes.AUDIT_START]: auditStartCoordinates,
};

const getCoordinates = (type, coordinates) => place[type](coordinates);

const infoAuditor = data => (
  <div className={styles.infoContainer}>
    <span className={styles.infoTitle}>
      {data.item?.name}
    </span>
    <Divider />
    <span>
      <Icon name="phone" />
      {data.item?.mobile_number}
    </span>
  </div>
);

const infoWindowContent = data => {
  const content = {
    [CoordinatesTypes.CURRENT]: () => infoAuditor(data),
    [CoordinatesTypes.TERM]: () => infoAuditor(data),
    [CoordinatesTypes.HOME]: () => infoAuditor(data),
    [CoordinatesTypes.SITE]: () => (
      <div className={styles.infoContainer}>
        <span className={styles.infoTitle}>
          {data.item?.site_name}
        </span>
        <Divider />
        <div>
          <span className={styles.locationIcon} />
          {' Postcode: '}
          {data.item?.site_post_code}
        </div>
      </div>
    ),
  };

  const titleMarker = data.title;

  return content[titleMarker] ? content[titleMarker]() : null;
};

let infoWindow;
const getInfoWindow = () => {
  if (!infoWindow) {
    infoWindow = new window.google.maps.InfoWindow({});
  }
  return infoWindow;
};

const createMarkers = (map, coordinates) => (
  coordinates?.map(item => {
    const marker = createMarker(item, map);
    marker.addListener(
      'click',
      () => {
        const iw = getInfoWindow();
        iw.setContent(ReactDOMServer.renderToString(infoWindowContent(item)));
        iw.open(map, marker);
      },
      { passive: true },
    );
    return marker;
  })
);

export const centerMap = ({ map, data }) => {
  const bounds = new window.google.maps.LatLngBounds();
  const renderBounds = coords => {
    if (coords?.current_coordinates) {
      bounds.extend(createLatLng(coords.current_coordinates));
    }
    if (coords?.home_coordinates) {
      bounds.extend(createLatLng(coords.home_coordinates));
    }
    if (coords?.audit_start_coordinates) {
      bounds.extend(createLatLng(coords.audit_start_coordinates));
    }
    if (coords?.site_coordinates) {
      bounds.extend(createLatLng(coords.site_coordinates));
    }
    if (coords?.term_coordinates) {
      bounds.extend(createLatLng(coords.term_coordinates));
    }
    map.fitBounds(bounds);
  };

  if (Array.isArray(data?.items)) {
    return data?.items?.map(item => renderBounds(item));
  }
  if (Array.isArray(data)) {
    return data.map(item => renderBounds(item));
  }
  return renderBounds(data);
};

const entityFilter = (map, item, fetchedAuditor) => ({
  [EntityState.DETAIL]: {
    [Entity.AUDITS]: () => createMarkers(map, [
      getCoordinates(CoordinatesTypes.SITE, item),
      getCoordinates(CoordinatesTypes.AUDIT_START, item),
    ]),
    [Entity.AUDITORS]: () => createMarkers(map, [
      getCoordinates(CoordinatesTypes.CURRENT, item),
      getCoordinates(CoordinatesTypes.HOME, item),
      getCoordinates(CoordinatesTypes.TERM, item),
    ]),
  },
  [EntityState.TABLE]: {
    [Entity.AUDITS]: () => createMarkers(map, [
      getCoordinates(CoordinatesTypes.SITE, item),
      getCoordinates(CoordinatesTypes.HOME, item),
      getCoordinates(CoordinatesTypes.TERM, item),
      getCoordinates(CoordinatesTypes.CURRENT, item),
      getCoordinates(CoordinatesTypes.CURRENT, fetchedAuditor),
    ]),
    [Entity.AUDITORS]: () => createMarkers(map, [
      getCoordinates(CoordinatesTypes.CURRENT, item),
      getCoordinates(CoordinatesTypes.HOME, item),
      getCoordinates(CoordinatesTypes.TERM, item),
    ]),
  },
});

const mergeDataArray = (arr1, arr2) => ((arr2 && arr2.length) ? [...arr1, ...arr2] : arr1);

export const entityMapFilter = ({ type, entity, map, data, fetchedAuditor }) => {
  let markers = [];
  if (Array.isArray(data?.items)) {
    markers = mergeDataArray(data.items, data.nearby_auditors)
      .map(item => entityFilter(map, item, fetchedAuditor)[type][entity]());
  } else if (Array.isArray(data)) {
    markers = data.map(item => entityFilter(map, item, fetchedAuditor)[type][entity]());
  } else {
    markers = entityFilter(map, data, fetchedAuditor)[type][entity]();
  }

  return markers.flat();
};

const auditorLegend = (
  <Grid className={styles.legendAudits} columns={3}>
    <Grid.Column>
      <Image src={RedMarker} />
      <span>
        {'Home'}
      </span>
    </Grid.Column>
    <Grid.Column>
      <Image src={LightBlueMarker} />
      <span>
        {'Term'}
      </span>
    </Grid.Column>
    <Grid.Column>
      <Image src={PinkMarker} />
      <span>
        {'Current'}
      </span>
    </Grid.Column>
  </Grid>
);
export const legendMap = ({ type, entity, role }) => {
  const props = {
    [EntityState.TABLE]: {
      [Entity.AUDITS]: () => (
        <div className={styles.auditTableMap}>
          {role !== UserRole.AUDITOR ? (
            <>
              <div className={styles.auditTableRow}>
                <div className={styles.auditTableLegend}>
                  <Image src={PurplePushpinMarker} />
                  <span>
                    {'Unassigned'}
                  </span>
                </div>
                <div className={styles.auditTableLegend}>
                  <Image src={YellowPushpinMarker} />
                  <span>
                    {'Assigned'}
                  </span>
                </div>
                <div className={styles.auditTableLegend}>
                  <Image src={GreenPushpinMarker} />
                  <span>
                    {'Approved'}
                  </span>
                </div>
                <div className={styles.auditTableLegend}>
                  <Image src={BluePushpinMarker} />
                  <span>
                    {'Submitted, Approving Query & Client Query'}
                  </span>
                </div>
              </div>
              <div className={styles.auditTableRow}>
                <div className={styles.auditTableLegend}>
                  <Image src={RedMarker} />
                  <span>
                    {'Auditor Home'}
                  </span>
                </div>
                <div className={styles.auditTableLegend}>
                  <Image src={LightBlueMarker} />
                  <span>
                    {'Auditor Term'}
                  </span>
                </div>
                <div className={styles.auditTableLegend}>
                  <Image src={PinkMarker} />
                  <span>
                    {'Auditor Current'}
                  </span>
                </div>
              </div>
            </>
          ) : null }
        </div>
      ),
      [Entity.AUDITORS]: () => auditorLegend,
    },
    [EntityState.DETAIL]: {
      [Entity.AUDITORS]: () => auditorLegend,
    },
  };

  return props[type]?.[entity] ? props[type]?.[entity]() : null;
};

export const createIconLayer = ({ id, items, icon, layerProps }) => {
  if (!items || items.length === 0) return null;

  return new IconLayer({
    id,
    data: items,
    getIcon: () => ({
      url: icon || defaultIcon,
      height: 24,
      width: 24,
      anchorY: 0,
      mask: true,
    }),
    getPosition: d => [d.site_coordinates.lng, d.site_coordinates.lat, 0],
    sizeUnits: 'common',
    sizeMaxPixels: 24,
    sizeMinPixels: 8,
    pickable: true,
    ...layerProps,
  });
};

export const LAYER_ID = {
  AUDITS: 'audits',
  AUDITORS: 'auditors',
};
