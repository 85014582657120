import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import ReactDropzone from 'react-dropzone';
import { Icon, Image, Label } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import { convertImages } from '../../../utils/imageConversion';
import { getThumbnailURL } from '../../pages/Auditing/helpers';
import FullScreenImageModal from '../FullScreenImageModal/FullScreenImageModal.component';
import styles from './Dropzone.module.scss';

const Dropzone = ({
  initialImages = [],
  onStateHandler,
  readOnly,
  accept,
  name,
  maxFiles,
  onDropFiles,
  onRemoveFile,
}) => {
  const alert = useAlert();
  const [modalImgSrc, setModalImgSrc] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(initialImages?.filter(Boolean) || []);
  }, [initialImages]);

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const isReadOnly = state === FieldStatus.READONLY || readOnly;

  return (
    <>
      {!isReadOnly && (
        <ReactDropzone
          multiple
          accept={accept}
          onDrop={async acceptedFiles => {
            if (maxFiles && maxFiles < acceptedFiles.length + images.length) {
              alert.error(`You have exceeded the maximum number of files: ${maxFiles}`);
              return;
            }
            const dropFiles = await Promise.all(acceptedFiles.map(async file => ({
              file: await convertImages(file),
              url: await getThumbnailURL(file),
            })));
            setImages(prevState => [...prevState, ...dropFiles.map(({ url }) => url)]);
            if (onDropFiles) onDropFiles(dropFiles);
          }}
          onDropRejected={fileRejections => {
            alert.error(`Error uploading file: ${fileRejections[0].file.name} - ${fileRejections[0].errors[0].message}`);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: styles.dropZone })}>
              <input {...getInputProps()} />
              <span className={styles.imageIcon}/>
              <p>
                {'Drop your images here, or '}
                <span>
                  {'browse.'}
                </span>
              </p>
            </div>

          )}
        </ReactDropzone>
      )}

      <div className={styles.thumb}>
        {images.map((imageUrl, imageIndex) => (
          <div key={imageIndex} className="ui image">
            {!isReadOnly && (
              <Label
                as="a"
                corner="right"
                size="small"
                onClick={() => {
                  setImages(prevState => prevState.filter((_, index) => index !== imageIndex));
                  if (onRemoveFile) onRemoveFile({ index: imageIndex, url: imageUrl });
                }}
              >
                <Icon className={styles.icon} name="trash"/>
              </Label>
            )}
            <Image
              className={styles.img}
              size="small"
              src={imageUrl}
              onClick={() => setModalImgSrc(imageUrl)}
            />
            <FullScreenImageModal
              imgSrc={modalImgSrc}
              onClose={() => setModalImgSrc(null)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

Dropzone.defaultProps = {
  accept: 'image/*',
  initialImages: [],
  maxFiles: null,
  name: null,
  onDropFiles: null,
  onRemoveFile: null,
  onStateHandler: null,
  readOnly: false,
};

Dropzone.propTypes = {
  accept: PropTypes.string,
  initialImages: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  onDropFiles: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default Dropzone;
