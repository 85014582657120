import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card } from 'semantic-ui-react';

import styles from './AuxiliaryPanel.module.scss';

const AuxiliaryPanel = ({
  condensed,
  content,
  dataCy,
  footer,
  header,
  initialCollapse,
  onCollapse,
  showCollapse,
  showHeader,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapse);

  return (
    <Card fluid className={styles.root} data-cy={dataCy} {...rest}>
      {showHeader && (
        <Card.Content extra className={styles.headerContainer}>
          <Card.Header>
            <div className={styles.headerRoot}>
              {header}
              {showCollapse ? (
                <Button
                  className={collapsed ? styles.collapsed : styles.notCollapsed}
                  onClick={() => {
                    if (onCollapse) onCollapse(!collapsed);
                    setCollapsed(!collapsed);
                  }}
                />
              ) : null}
            </div>
          </Card.Header>
        </Card.Content>
      )}
      <div className={collapsed ? styles.collapsedContent : null}>
        <Card.Content className={condensed ? null : styles.contentContainer}>
          {content}
        </Card.Content>
        {footer ? (
          <Card.Content extra>
            {footer}
          </Card.Content>
        ) : null}
      </div>
    </Card>
  );
};

AuxiliaryPanel.defaultProps = {
  condensed: false,
  dataCy: 'auxiliary-panel',
  footer: null,
  initialCollapse: false,
  onCollapse: null,
  showCollapse: true,
  showHeader: true,
};

AuxiliaryPanel.propTypes = {
  content: PropTypes.element.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  condensed: PropTypes.bool,
  dataCy: PropTypes.string,
  footer: PropTypes.element,
  initialCollapse: PropTypes.bool,
  showCollapse: PropTypes.bool,
  showHeader: PropTypes.bool,
  onCollapse: PropTypes.func,
};

export default AuxiliaryPanel;
