/* eslint-disable you-dont-need-lodash-underscore/get */
import _get from 'lodash/get';

import { FieldScope, TableViewActions } from './helpers';

const logicFiles = require.context('./entity');

const logicModules = logicFiles.keys().map(logicFiles);
const logics = logicModules.reduce((acc, reducer) => {
  Object.entries(reducer.default).forEach(([key, value]) => {
    Object.assign(acc, { [key]: value });
  });
  return acc;
}, {});

const fieldVisibility = ({ entity, scope, role }) => fieldName => {
  const propsToCheck = _get(logics, [entity, scope, role], []);
  return propsToCheck.includes(fieldName);
};

const columnsVisibility = ({ entity, scope, role, extra }) => (
  _get(logics, extra ? [entity, scope, role, extra] : [entity, scope, role], [])
);

export {
  columnsVisibility,
  FieldScope,
  fieldVisibility,
  TableViewActions,
};
