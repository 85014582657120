import { Field, FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Confirm, Divider, Form, Grid, Modal } from 'semantic-ui-react';

import { exportAPI } from '../../../../../../api';
import { enumOptionsSelector } from '../../../../../../state/constants/selectors';
import { LoadingPlaceholder } from '../../../../../common';
import { uuidType } from '../../../../../types';
import EntityExportDraggable from '../EntityExportDraggable/EntityExportDraggable.component';
import styles from './EntityExportModal.module.scss';

const userRoleSelector = state => enumOptionsSelector(state, 'user_role_type');

const EntityExportModal = ({ open, exportId, onCancel, onCopy, onDelete, onSave }) => {
  const { entity } = useParams();
  const userRoleOptions = useSelector(userRoleSelector);
  const availableRoles = userRoleOptions.filter(role => role.value !== 'auditor' && role.value !== 'data');

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const { data } = useQuery(
    [entity, exportId],
    () => exportAPI.fetch(entity, exportId),
    { enabled: Boolean(open && exportId) },
  );

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={true}
        open={open}
        onClose={onCancel}
      >
        <Modal.Header>
          {'Export template editor'}
        </Modal.Header>
        {data
          ? (
            <Formik initialValues={{
              ...data,
              roles: data.roles.length > 0 ? data.roles : [],
            }}
            >
              {({ values }) => (
                <>
                  <Modal.Content>
                    <Form>
                      <Form.Field>
                        <Field as={Form.Input} label="Name" name="name" />
                      </Form.Field>
                      <Divider />
                      <div className={styles.columnsHeader}>
                        {'Roles (Data user has always default access to every template)'}
                      </div>
                      <Grid columns={4}>
                        <FieldArray name="roles">
                          {({ push, remove }) => {
                            const handleToggle = (roleId, checked) => {
                              if (checked) {
                                push(roleId);
                              } else {
                                const foundIndex = values.roles.findIndex(r => r === roleId);
                                remove(foundIndex);
                              }
                            };
                            return availableRoles.map((role, index) => (
                              <Grid.Column key={index}>
                                <Form.Field>
                                  <Field
                                    toggle
                                    as={Checkbox}
                                    checked={values.roles.includes(role.value)}
                                    label={role.text}
                                    name={`availableRoles.${index}`}
                                    onChange={(_e, { checked }) => handleToggle(role.value, checked)}
                                  />
                                </Form.Field>
                              </Grid.Column>
                            ));
                          }}
                        </FieldArray>
                      </Grid>
                      <Divider />
                      <EntityExportDraggable values={values}/>
                      <Divider />
                      <Button onClick={() => handleDelete()}>
                        {'Delete'}
                      </Button>
                      <Button onClick={() => onCopy(data)}>
                        {'Copy'}
                      </Button>
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={onCancel}>
                      {'Cancel'}
                    </Button>
                    <Button primary onClick={() => onSave(exportId, values)}>
                      {'Save'}
                    </Button>
                  </Modal.Actions>
                </>
              )}
            </Formik>
          )
          : <LoadingPlaceholder />
        }
      </Modal>
      <Confirm
        open={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={() => {
          setDeleteModalOpen(false);
          onDelete(exportId);
        }}
      />
    </>
  );
};

EntityExportModal.defaultProps = {
  exportId: null,
};

EntityExportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  exportId: uuidType,
};

export default EntityExportModal;
