import './styles/index.scss';
import 'semantic-ui-less/semantic.less';
import 'react-widgets/lib/less/react-widgets.less';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-sortable-tree/style.css';
import './config/configureSentry'; // Enable tracing for Sentry

import * as Sentry from '@sentry/react';
import { ConfigCatProvider } from 'configcat-react';
import React from 'react';
import { Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { AlertTemplate, NotificationTemplate, WaitPlaceholder } from './components/common';
import App from './components/layout/App/App.component';
import { alertsConfig, appConfig, notificationAlertsConfig, queryConfig } from './config';
import { ROOT_ELEMENT_ID } from './constants';
import { NotificationAlertContext } from './context';
import registerSW from './register-service-worker';
import { persistor, store } from './state/store';
import isMobile from './utils/isMobile';

const queryClient = new QueryClient(queryConfig);

const sdkOptions = {
  pollIntervalSeconds: 30,
};

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<WaitPlaceholder/>} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <AlertProvider template={AlertTemplate} {...alertsConfig}>
            <AlertProvider
              context={NotificationAlertContext}
              template={NotificationTemplate}
              {...notificationAlertsConfig}
            >
              <ConfigCatProvider
                options={sdkOptions}
                sdkKey={appConfig.configCatSdkKey}
              >
                <App/>
              </ConfigCatProvider>
            </AlertProvider>
          </AlertProvider>
          {window.Cypress ? null : <ReactQueryDevtools initialIsOpen={false}/>}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById(ROOT_ELEMENT_ID), // Do not change 'root' id!
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

if (isMobile) {
  registerSW();
}
