import classnames from 'classnames';
import { Field, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'semantic-ui-react';

import FieldStatus from '../../../fieldLogic/fieldStatus';
import styles from './FormFieldWrapper.module.scss';
import FormFieldTemplate from './Template/FormFieldTemplate.component';

const FormFieldWrapper = ({
  dataCy,
  help,
  inline,
  label,
  labelStyle,
  labelTooltip,
  name,
  valueStyle,
  onChange,
  onStateHandler,
  readOnly,
  required,
  type,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();

  const dataCyPrefix = dataCy || name;

  let state = FieldStatus.EDITABLE;

  if (onStateHandler) {
    state = onStateHandler(name);
  }

  const handleBlur = e => {
    const newVal = !Number.isNaN(Number.parseFloat(e.target.value))
      ? Number.parseFloat(e.target.value)
      : 0;
    if (onChange) {
      onChange(newVal);
    } else {
      setFieldValue(field.name, newVal);
    }
  };

  const handleChange = (e, newValue) => {
    const validNumber = /^\d*\.?\d*$/;
    if (onChange && validNumber.test(newValue)) {
      onChange(String(newValue)[0] === '0'
        ? Number.parseFloat(e.target.value)
        : newValue === ''
          ? 0
          : newValue);
    }
  };

  if (state === FieldStatus.HIDDEN) {
    return null;
  }

  const printReadOnlyField = value => (<span className={classnames(styles.displayValue, { [valueStyle]: valueStyle })}>
    {value}
  </span>);

  const readOnlyField = singleField => {
    const valueType = typeof singleField.value;
    if (valueType === 'object' && singleField.value) {
      return (<>
        {Object.keys(singleField?.value).map(el => (printReadOnlyField(singleField.value[el])))}
      </>);
    }
    return printReadOnlyField(field.value);
  };

  const editableField = () => (
    <Field
      as={type === 'textarea' ? Form.TextArea : Form.Input}
      className={classnames({ [styles.textarea]: type === 'textarea' })}
      data-cy={`${dataCyPrefix}-field`}
      error={meta.touched ? meta.touched && meta.error : meta.error}
      name={name}
      readOnly={readOnly}
      required={required}
      step={type === 'number' ? 'any' : null}
      type={type !== 'number' ? type : 'input'}
      onBlur={type === 'number' ? ev => handleBlur(ev) : () => { }}
      onChange={(_e, { value }) => {
        const parsedValue = value.trimStart();
        if (type === 'number') {
          handleChange(_e, value);
        }
        helpers.setValue(parsedValue);
        if (onChange) {
          onChange(parsedValue);
        }
      }}
      {...rest}
    />
  );

  return (
    <FormFieldTemplate
      dataCy={dataCyPrefix}
      help={help}
      inline={inline}
      label={label}
      labelStyle={labelStyle}
      labelTooltip={labelTooltip}
      required={required}
    >
      {state === FieldStatus.READONLY || readOnly ? readOnlyField(field) : editableField()}
    </FormFieldTemplate>
  );
};

FormFieldWrapper.defaultProps = {
  dataCy: null,
  help: null,
  inline: false,
  label: null,
  labelStyle: null,
  labelTooltip: null,
  onChange: null,
  onStateHandler: null,
  readOnly: false,
  required: false,
  type: 'text',
  valueStyle: null,
};

FormFieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  inline: PropTypes.bool,
  label: PropTypes.string,
  labelStyle: PropTypes.string,
  labelTooltip: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'textarea', 'password']),
  valueStyle: PropTypes.string,
  onChange: PropTypes.func,
  onStateHandler: PropTypes.func,
};

export default FormFieldWrapper;
