import { UserRole } from '../../constants';
import { TableViewActions } from '../helpers';

export const AuditFields = {
  internalId: 'internal_id',
  orderInternalId: 'order_internal_id',
  siteCode: 'site_code',
  siteName: 'site_name',
  sitePostCode: 'site_post_code',
  clientName: 'client_name',
  auditorName: 'auditor_name',
  itemToOrder: 'item_to_order',
  orderScheduleType: 'order_schedule_type',
  visitInfo: 'visit_info',
  deadlineDate: 'deadline_date',
  orderEndDate: 'order_end_date',
  orderStartDate: 'order_start_date',
  startDate: 'start_date',
  endDate: 'end_date',
  dateOfVisit: 'date_of_visit',
  surveyResult: 'survey_result',
  matchings: 'matchings',
  // tokens: 'audit_token',
  tokens: 'tokens',
};

const tableViewActions = {
  [UserRole.AREA_MANAGER]: [
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
  [UserRole.CLIENT]: [
    TableViewActions.EXPORT,
  ],
  [UserRole.CLIENT_SERVICES]: [
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
  [UserRole.DATA]: [
    TableViewActions.EXPORT_EDIT_TEMPLATE,
    TableViewActions.EXPORT,
    TableViewActions.IMPORT,
  ],
};

const tableViewColumns = {
  [UserRole.AUDITOR]: [
    // TODO
  ],
  [UserRole.AREA_MANAGER]: [
    AuditFields.internalId,
    AuditFields.orderInternalId,
    AuditFields.siteName,
    AuditFields.sitePostCode,
    AuditFields.clientName,
    AuditFields.auditorName,
    AuditFields.orderScheduleType,
    AuditFields.itemToOrder,
    AuditFields.visitInfo,
    AuditFields.deadlineDate,
    AuditFields.orderEndDate,
    AuditFields.dateOfVisit,
    AuditFields.surveyResult,
    AuditFields.orderStartDate,
    AuditFields.startDate,
    AuditFields.endDate,
  ],
  [UserRole.CLIENT]: [
    AuditFields.internalId,
    AuditFields.orderInternalId,
    AuditFields.siteCode,
    AuditFields.siteName,
    AuditFields.sitePostCode,
    AuditFields.itemToOrder,
    AuditFields.orderScheduleType,
    AuditFields.dateOfVisit,
    AuditFields.surveyResult,
  ],
  [UserRole.CLIENT_SERVICES]: [
    AuditFields.internalId,
    AuditFields.orderInternalId,
    AuditFields.siteName,
    AuditFields.sitePostCode,
    AuditFields.clientName,
    AuditFields.auditorName,
    AuditFields.orderScheduleType,
    AuditFields.visitInfo,
    AuditFields.deadlineDate,
    AuditFields.orderEndDate,
    AuditFields.dateOfVisit,
    AuditFields.surveyResult,
    AuditFields.orderStartDate,
    AuditFields.startDate,
    AuditFields.endDate,
  ],
  [UserRole.DATA]: [
    AuditFields.internalId,
    AuditFields.orderInternalId,
    AuditFields.siteName,
    AuditFields.sitePostCode,
    AuditFields.clientName,
    AuditFields.auditorName,
    AuditFields.orderScheduleType,
    AuditFields.visitInfo,
    AuditFields.deadlineDate,
    AuditFields.orderEndDate,
    AuditFields.surveyResult,
    AuditFields.tokens,
    AuditFields.matchings,
    AuditFields.orderStartDate,
    AuditFields.startDate,
    AuditFields.endDate,
    AuditFields.itemToOrder,
  ],
};

export default {
  audits: {
    tableViewActions,
    tableViewColumns,
  },
};
